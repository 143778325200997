import getByDot from './getByDot'

export default function getHsCalculationMissingFields (data) {
  let requiredFields = [
    // { path: 'product', message: 'Es ist kein Produkt ausgewählt' },
    { path: 'classicCarId', message: 'Es ist kein Fahrzeug gewählt' },
    { path: 'carData.erstzulassung', message: 'Erstzulassung ist nicht angegeben' },
    { path: 'carData.versicherungswert', message: 'Es ist kein Versicherungswert angegeben' },
    { path: 'carData.laufleistung', message: 'Es ist keine Laufleistung angegeben' },
    { path: 'carData.abstellplatz', message: 'Es ist kein Abstellplatz angegeben' },
    { path: 'carData.nutzung', message: 'Es ist keine Fahrzeug Nutzung angegeben' },
    { path: 'fahrerkreis', message: 'Es ist kein Fahrerkreis angegeben' },
    { path: 'geburtsdatumFahrerkreisMax', message: 'Es ist kein Geburtsdatum gewählt' },
    { path: 'carData.kennzeichenart', message: 'Es ist keine Kennzeichen Art angegeben' },
    { path: 'carData.saisonkennzeichenvon', message: 'Es ist kein Saisonbeginn ausgewählt' },
    { path: 'carData.saisonkennzeichenbis', message: 'Es ist kein Saisonende ausgewählt' },
    { path: 'zahlweise', message: 'Es ist keine Zahlungsperiode gewählt' }
    // { path: 'customerId', message: 'Es ist kein Kunde gewählt' },
  ]
  // Saisonzeitraum rausfiltern, wenn nicht benötigt
  if (data.carData.kennzeichenart !== 3 && data.carData.kennzeichenart !== 4) requiredFields = requiredFields.filter(field => field.path !== 'carData.saisonkennzeichenvon' && field.path !== 'carData.saisonkennzeichenbis')

  // ClassicCarID rausfiltern, wenn Fahrzeugdaten manuelle eingegeben wurden. (carNotFound-Checkbox)
  if (data.carNotFound) requiredFields = requiredFields.filter(field => field.path !== 'classicCarId')
  return requiredFields
    .filter(field => !getByDot(data, field.path))
    .map(field => field.message)
}
