<template>
  <div>
    <BasicInput
      v-model="order.carData.motorstaerke"
      :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
      :horizontal="true"
      placeholder=""
      label="Motorstärke"
      :required="required"
      type="number"
      min="0"
      max="1000"
    >
      <template #append>
        <span class="input-group-text">kW</span>
      </template>
    </BasicInput>
    <div
      v-if="showHardKnockout"
      class="bg-danger text-white p-2 rounded"
    >
      <h5 class="mb-0">
        Achtung
      </h5>
      Wegen des eingegebenen Wertes zu dem Feld Motorstärke, kann der Antrag nicht bearbeitet werden.
    </div>
  </div>
</template>
<script>
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
export default {
  components: { BasicInput },
  props: {
    order: {
      type: Object,
      required: true
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showHardKnockout: false
    }
  },
  watch: {
    'order.carData.motorstaerke': {
      handler () {
        if (this.order.carData.motorstaerke > this.order.motorstaerkeMax) {
          this.showHardKnockout = true
          this.$emit('editDisable', true)
        } else {
          this.showHardKnockout = false
          this.$emit('editDisable', false)
        }
      }
    }
  }
}
</script>
