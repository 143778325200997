<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Weitere Merkmale zur Beitragsberechnung
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        @submit.prevent="$emit('save')"
      >
        <BasicSelect
          v-model="order.carData.laufleistung"
          required
          name="Jährliche Laufleistung"
          label="Jährliche Laufleistung"
          placeholder="Bitte auswählen"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          horizontal
          :options="laufleistung"
        />
        <Hardknockout
          v-if="isHardknockoutLaufleistung"
          field-name="Jährliche Laufleistung"
        />
        <BasicTypeahead
          id="abstellplatzPlz"
          v-model="order.abstellplatzPlz"
          auto-select-first
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="PLZ"
          :query="searchCityByZip"
          :input-formatter="zipInputFormatter"
          :result-formatter="cityResultFormatter"
          :horizontal="true"
          label="PLZ / Ort Abstellplatz"
          :margin="false"
          required
          infotext="Bitte tragen Sie die Postleitzahl des überwiegenden Abstellplatzes ein."
          @unselect="order.abstellplatzPlz = null"
          @input="setValue"
        />
        <Hardknockout
          v-if="isHardknockoutPLZ"
          field-name="PLZ / Ort Abstellplatz"
        />
        <basic-select
          v-model="order.carData.abstellplatz"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Abstellplatz"
          required
          :options="abstellplaetze"
          placeholder="Bitte auswählen"
          infotext="Bitte wählen Sie den überwiegenden Abstellplatz Ihres Fahrzeuges aus."
        />
        <basic-select
          v-model="order.carData.nutzung"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Nutzung"
          required
          :options="nutzungen"
          placeholder="Bitte auswählen"
        >
          <template #slotinfotext>
            <p>
              <span class="font-weight-bold">reine Liebhabernutzung  (ohne Vermietung) </span><br>
              Gelegentliche Nutzung des Fahrzeugs ohne Vermietung, z.B. Spazier-, Ausflugs- oder Urlaubsfahrten, Teilnahme an Veranstaltungen, Probe- und Überführungsfahrten.
            </p>
            <p>
              <span class="font-weight-bold">auch alltägliche Nutzung (ohne Vermietung)</span><br>
              Über die reine Liebhabernutzung hinaus wird das Fahrzeug regelmäßig für alltägliche Fahrten genutzt. Dazu zählen z. B. Fahrten von und zur Arbeitsstätte, Dienstfahrten und Fahrten zur Bewältigung des Alltags (Einkäufe, Schule etc.).
            </p>
            <p>
              <span class="font-weight-bold">Chauffeur-Vermietung</span><br>
              Chauffeurs-Mietwagen sind Liebhaber- oder Premium-Pkw mit denen ein genehmigungspflichtiger Gelegenheitsverkehr gewerbsmäßig betrieben wird (unter Ausschluss der Taxen, Kraftomnibusse, Güterfahrzeuge und Selbstfahrervermietfahrzeuge).
            </p>
            <p>
              <span class="font-weight-bold">Selbstfahrervermietung</span><br>
              Selbstfahrervermietfahrzeuge sind Liebhaber- oder Premium-Kfz, die ohne Gestellung eines Fahrers vermietet werden
            </p>
            <p>
              <span class="font-weight-bold">Sonstige Nutzung (ohne Vermietung)</span><br>
              Alles, was nicht über die o.g. Punkte erfasst ist.
            </p>
          </template>
        </basic-select>
        <Hardknockout
          v-if="isHardknockoutNutzung"
          field-name="Nutzung"
        />
        <Ueberpruefung
          v-if="isUeberpruefungNutzung"
          field-name="Nutzung"
        />
        <basic-select
          v-model="order.fahrerkreis"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Fahrerkreis"
          required
          :options="filteredFahrerkreise"
          placeholder="Bitte auswählen"
          infotext="Von welchen Personen wird das Fahrzeug regelmäßig gefahren? Junge Fahrer, die das Fahrzeug im Rahmen des begleiteten Fahrens lenken, müssen nicht genannt werden - diese Fahrer sind automatisch beitragsfrei mitversichert."
        />
        <transition-group name="slide-fade">
          <basic-datepicker
            v-if="order.fahrerkreis && order.fahrerkreis !== 1"
            key="1"
            v-model="order.geburtsdatumFahrerkreisMin"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Geburtstag jüngster Fahrer"
            placeholder="TT.MM.JJJJ"
            required
            :disabled-dates="disabledDatesBirthdayFahrerkreis"
          />
          <basic-datepicker
            v-if="order.fahrerkreis && order.fahrerkreis !== 1"
            key="2"
            v-model="order.geburtsdatumFahrerkreisMax"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Geburtstag ältester Fahrer"
            placeholder="TT.MM.JJJJ"
            required
            :disabled-dates="disabledDatesBirthdayFahrerkreis"
          />
          <basic-datepicker
            v-if="order.fahrerkreis && order.fahrerkreis === 1"
            key="3"
            v-model="order.geburtsdatumFahrerkreisMax"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Geburtstag"
            placeholder="TT.MM.JJJJ"
            required
            :disabled-dates="disabledDatesBirthdayFahrerkreis"
          />
        </transition-group>
        <Hardknockout
          v-if="isHardknockoutAlter"
          field-name="Fahrerkreisalter"
        />
        <Hardknockout
          v-if="isHardknockoutAlterPC"
          message="Fahrer jünger 30 können nicht auf PremiumCars versichert werden"
        />
        <Hardknockout
          v-if="isHardknockoutAlterVN"
          message="Alter des Versicherungsnehmers / Kunden wird nicht im Fahrerkreis abgebildet."
        />
        <basic-select
          v-model="order.carData.kennzeichenart"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Kennzeichen Art"
          required
          :options="kennzeichenArten"
          placeholder="Bitte auswählen"
        />
        <Ueberpruefung
          v-if="isUeberpruefungKennzeichenart"
          field-name="Kennzeichen Art"
        />
        <template v-if="order.carData.kennzeichenart === 3 || order.carData.kennzeichenart === 4">
          <BasicSelect
            v-model="order.carData.saisonkennzeichenvon"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Saison von"
            placeholder="Bitte wählen"
            :options="allMonths"
            required
          />
          <BasicSelect
            v-model="order.carData.saisonkennzeichenbis"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Saison bis"
            placeholder="Bitte wählen"
            :options="filteredMonths"
            required
          />
          <!-- <Hardknockout
            v-if="isHardknockoutSaisondauer"
            field-name="Saisondauer"
          /> -->
        </template>
        <BasicSelect
          v-model="order.vorschaeden"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          horizontal
          required
          name="Vorschäden"
          label="Sind in den vergangenen 3 Jahren mehr als 2 selbstverschuldete Schäden eingetreten?"
          placeholder="Bitte auswählen"
          :options="[
            { label: 'Nein', value: false },
            { label: 'Ja', value: true },
          ]"
          infotext="Bitte berücksichtigen Sie dabei nur die Schäden, die Sie verursacht haben, oder Schäden, bei denen Ihnen eine Teilschuld zugesprochen wurde bzw. Schäden, deren Kosten von Ihrer damaligen Versicherung übernommen wurden. Die Frage bezieht sich auf alle Ihre Fahrzeuge (inkl. Alltagsfahrzeuge)."
          @change="checkHardKnockOutPreDamages()"
        />
        <Hardknockout
          v-if="isHardknockoutVorschaeden"
          field-name="Vorschäden"
        />
        <FormNote
          v-if="isFilteredZahlweisen"
          message="Hinweis: Das gewünschte Kennzeichen ist nur in Verbindung mit einer jährlichen Zahlungsperiode möglich."
        />
        <basic-select
          v-model="order.zahlweise"
          :columns="{
            label: ['col-12', 'col-md-4'],
            input: ['col-12', 'col-md-8'],
          }"
          :horizontal="true"
          label="Zahlungsperiode"
          name="Zahlungsperiode"
          required
          :options="filteredZahlweisen"
          infotext="Bitte wählen Sie hier die gewünschte Zahlungsperiode aus."
        />
        <FormNote
          v-if="isFilteredZahlarten"
          message="Die gewünschte Zahlungsperiode ist nur in Verbindung mit einem SEPA-Lastschriftverfahren möglich."
        />
      </form>
    </template>
  </ca-card>
</template>

<script>
import CaCard from '@/components/Card'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker'

import enumsToSelectOptions from '@/helpers/enumToSelectOptions'

import fahrerkreise from '@/resources/enums/fahrerkreise.js'
import nutzungen from '@/resources/enums/nutzungen.js'
import kennzeichenArten from '@/resources/enums/kennzeichenArten.js'
import abstellplaetze from '@/resources/enums/abstellplaetze.js'
import FormNote from '@/components/Forms/Order/FormNote.vue'
import moment from 'moment'
import zahlweisen from '@/resources/enums/zahlweisen.js'
import zahlarten from '@/resources/enums/zahlarten.js'
import Hardknockout from './Hardknockout.vue'
import getSegmentByEZ from '@/helpers/getSegmentByEZ'
import Ueberpruefung from './Ueberpruefung.vue'

import months from '@/resources/enums/months.js'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead.vue'
import feathersClient from '@/api'
import laufleistung from '@/resources/enums/laufleistung'
import checkAlterVersicherungsnehmerInFahrerkreis from '@/resources/helpers/checkAlterVersicherungsnehmerInFahrerkreis'

export default {
  inject: ['$validator'],
  name: 'HssOtherCalculationForm',
  components: {
    CaCard,
    BasicSelect,
    BasicDatepicker,
    FormNote,
    Hardknockout,
    Ueberpruefung,
    BasicTypeahead
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      bank: null,
      // Hardknockout
      isHardknockoutPLZ: false
    }
  },
  computed: {
    zahlweisen () {
      return Object.values(zahlweisen).map(zahlweise => ({ label: zahlweise.human, value: zahlweise.api }))
    },
    zahlarten () {
      return Object.values(zahlarten).map(zahlart => ({ label: zahlart.human, value: zahlart.api }))
    },
    laufleistung () {
      return Object.values(laufleistung).map(laufleistung => ({ label: laufleistung.human, value: laufleistung.api }))
    },
    isFilteredZahlweisen () {
      if (!this.order.carData?.kennzeichenart) return false
      return this.order.carData?.kennzeichenart === 3 || this.order.carData?.kennzeichenart === 4
    },
    filteredZahlweisen () {
      if (!this.isFilteredZahlweisen) return this.zahlweisen
      return this.zahlweisen.filter(zahlweise => zahlweise.value === 112)
    },
    isFilteredZahlarten () {
      if (!this.order.zahlweise) return false
      return this.order.zahlweise !== 112
    },
    fahrerkreise () {
      return enumsToSelectOptions(fahrerkreise)
    },
    isFilteredFahrerkreise () {
      if (!this.customer.name?.ANR && !this.customer.kind) return false
      return this.customer.name.ANR === 'f' || this.customer.kind === 'FIRMA'
    },
    filteredFahrerkreise () {
      if (!this.isFilteredFahrerkreise) return this.fahrerkreise
      return this.fahrerkreise.filter(fahrerkreis => fahrerkreis.value === 4)
    },
    kennzeichenArten () {
      return enumsToSelectOptions(Object.values(kennzeichenArten)
        .filter(kennzeichenArt => {
          // Das Kennzeichen muss entweder keine Restriction haben oder der Account muss für eines des scenarioIds befugt sein
          return !kennzeichenArt.restrictToScenarioIds ||
            kennzeichenArt.restrictToScenarioIds.some(scenarioId => this.$can(scenarioId))
        }))
    },
    nutzungen () {
      return enumsToSelectOptions(nutzungen)
    },
    abstellplaetze () {
      return enumsToSelectOptions(abstellplaetze)
    },
    disabledDatesBirthdayFahrerkreis () {
      return {
        to: moment('1910-01-01').startOf('day').toDate(),
        from: moment().startOf('day').subtract(17, 'years').toDate()
      }
    },
    allMonths () {
      return Object.values(months).map(month => ({ label: month.human, value: month.api }))
    },
    filteredMonths () {
      // Saisonbeginn = 1. des gewählten Monats
      // Saisonende = 30. des gewählten Monats
      // Saison muss mindestens 2 Monate und maximal 11 Monate dauern
      // => Saison darf nicht von Monat bis Vormonat sein (1 Jahr) oder von Monat bis Monat (1 Monat bzw. 13 Monate)
      if (!this.order.carData?.saisonkennzeichenvon) return this.allMonths
      const copyMonths = [...this.allMonths]
      if (this.order.carData.saisonkennzeichenvon === 0) {
        copyMonths.pop() // Wenn Januar gewählt, entferne Dezember
      } else {
        copyMonths.splice(this.order.carData.saisonkennzeichenvon - 2, 1) // Entferne Vormonat
      }
      const indexForbiddenMonth = copyMonths.findIndex(month => month.value === this.order.carData.saisonkennzeichenvon)
      copyMonths.splice(indexForbiddenMonth, 1) // Entferne gewählten Monat
      return copyMonths
    },
    // Hardknockouts und Überprüfungen
    isHardknockoutNutzung () {
      if (!this.order.carData?.nutzung) return false
      const invalidSegments = ['Newtimer', 'PremiumCars']
      const invalidNutzung = [2, 3, 4, 5] // 2 = alltägliche Nutzung 3 = Chaffeur 4 = Selbstfahrervermietung 5=Sonstige nutzung
      if (invalidNutzung.includes(this.order.carData.nutzung) && invalidSegments.includes(getSegmentByEZ(this.order.carData.erstzulassung))) return true // keine alltägliche/Selbstfahrvermietung/Chaffuer/sonstige Nutzung für Newtimer und PremiumCars
      return false
    },
    isUeberpruefungNutzung () {
      if (!this.order.carData?.nutzung) return false
      const invalidSegments = ['Oldtimer', 'Youngtimer']
      const invalidNutzung = [3, 4, 5] // 3 = Chaffeur 4 = Selbstfahrervermietung 5=Sonstige nutzung
      if (invalidNutzung.includes(this.order.carData.nutzung) && invalidSegments.includes(getSegmentByEZ(this.order.carData.erstzulassung))) return true // Überprüfung bei Selbstfahrvermietung/ Chaffuer/ Sonstige für Oldtimer und Youngtimer
      return false
    },
    isHardknockoutLaufleistung () {
      if (!this.order.carData?.laufleistung || !this.order.carData?.erstzulassung) return false
      const segment = getSegmentByEZ(this.order.carData.erstzulassung)
      if (this.order.carData.laufleistung > 15000 && segment === 'Newtimer') return true
      if (this.order.carData.laufleistung > 10000 && segment === 'PremiumCars') return true
      return false
    },
    isUeberpruefungKennzeichenart () {
      if (!this.order.carData?.kennzeichenart) return false
      const invalidNumberplates = [6, 7, 10, 12]
      // 6 = 'KurzzeitKennzeichen', 7 = 'Rotes Dauer Kennzeichen 07',
      // 10 = 'Ohne Kennzeichen', 12 = 'E-Kennzeichen'
      return invalidNumberplates.includes(this.order.carData.kennzeichenart)
    },
    // isHardknockoutSaisondauer () {
    //   if (!this.order.carData?.saisonkennzeichenvon || !this.order.carData?.saisonkennzeichenbis) return false
    //   const from = this.order.carData.saisonkennzeichenvon
    //   const to = this.order.carData.saisonkennzeichenbis
    //   let durationInMonths = (to + 1) - from // 1 wird addiert, da der Monat noch mitzählt.
    //   if (from > to) durationInMonths += 12 // 12 addieren, da Saisonzeitraum über Jahregrenze hinausgeht
    //   return durationInMonths < 2 || durationInMonths > 11
    // },
    isHardknockoutVorschaeden () {
      if (!this.order.vorschaeden) return false
      return this.order.vorschaeden
    },
    isHardknockoutAlter () {
      if (!this.order.geburtsdatumFahrerkreisMin || !this.order.geburtsdatumFahrerkreisMax) return false
      return moment(this.order.geburtsdatumFahrerkreisMin).isBefore(moment(this.order.geburtsdatumFahrerkreisMax)) // wenn Min vor Max = Hardknockout
    },
    isHardknockoutAlterPC () { // Fahreralter < 30 bei PremiumCars -> Hardknockout
      if (!this.order.carData?.erstzulassung || (!this.order.geburtsdatumFahrerkreisMin && !this.order.geburtsdatumFahrerkreisMax)) return false
      let youngestFahrerkreis = this.order.geburtsdatumFahrerkreisMax
      const minAge = moment().subtract(30, 'years')
      if (this.order.geburtsdatumFahrerkreisMin && this.order.fahrerkreis > 1) youngestFahrerkreis = this.order.geburtsdatumFahrerkreisMin
      return minAge.isBefore(moment(youngestFahrerkreis)) && getSegmentByEZ(this.order.carData.erstzulassung) === 'PremiumCars'
    },
    isHardknockoutAlterVN () { // Fahreralter nicht im Fahrerkreis enthalten
      if (!this.customer || !this.order.geburtsdatumFahrerkreisMax) return false
      return checkAlterVersicherungsnehmerInFahrerkreis(this.customer, this.order)
    },
    isSomeHardknockouts () {
      return this.isHardknockoutPLZ || this.isHardknockoutNutzung || this.isHardknockoutLaufleistung || this.isHardknockoutVorschaeden || this.isHardknockoutAlter || this.isHardknockoutAlterPC || this.isHardknockoutAlterVN
    },
    isSomeUeberpruefung () {
      return this.isUeberpruefungNutzung || this.isUeberpruefungKennzeichenart
    }
  },
  watch: {
    isFilteredZahlarten: function () {
      if (!this.filteredZahlarten.find(zahlart => zahlart.value === this.order.zahlart)) this.$delete(this.order, 'zahlart')
    },
    isFilteredZahlweisen: function () {
      if (!this.filteredZahlweisen.find(zahlweise => zahlweise.value === this.order.zahlweise)) this.$delete(this.order, 'zahlweise')
    },
    isFilteredFahrerkreise: function () {
      if (!this.filteredFahrerkreise.find(fahrerkreis => fahrerkreis.value === this.order.fahrerkreis)) this.$delete(this.order, 'fahrerkreis')
    },
    filteredMonths: function () {
      if (!this.filteredMonths.find(month => month.value === this.order.carData.saisonkennzeichenbis)) this.$delete(this.order.carData, 'saisonkennzeichenbis')
    },
    'order.fahrerkreis': function (newVal, oldVal) {
      if (newVal === 1) this.$delete(this.order, 'geburtsdatumFahrerkreisMin')
    },
    'order.abstellplatzPlz': {
      handler: 'checkPLZ'
    },
    isSomeUeberpruefung: {
      handler: function (newVal) {
        this.$emit('ueberpruefung-change', newVal)
      }
    },
    isSomeHardknockouts: {
      handler: function (newVal) {
        this.$emit('hardknockout-change', newVal)
      }
    }
  },
  methods: {
    async searchCityByZip (zip) {
      let result = await feathersClient.service('zipcodes').find({
        query: {
          $limit: 50,
          $or: [
            { zip: { $regex: '^' + zip, $options: 'i' } },
            { name: { $regex: '^' + zip, $options: 'i' } }
          ]
        }
      })
      result = result.data.map(zipcodeObj => {
        return { ...zipcodeObj, value: `${zipcodeObj.zip}` }
      })
      return result
    },
    cityResultFormatter (city) {
      return `${city.zip} ${city.name}`
    },
    zipInputFormatter (city) {
      if (typeof city === 'object') {
        return city.zip
      }
      return city
    },
    setValue (event) {
      if (typeof event === 'object') {
        this.$set(this.order, 'abstellplatzPlz', event.value)
      }
      return event
    },
    async checkPLZ () {
      const result = await feathersClient.service('hsdata-kd-plz').find({ query: { plz: this.order.abstellplatzPlz } })
      this.isHardknockoutPLZ = result.total === 0
    }
  }
}
</script>
