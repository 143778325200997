<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Fahrzeug
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        @submit.prevent="$emit('save')"
      >
        <basic-typeahead
          v-if="!order.carNotFound"
          id="fahrzeug"
          v-model="order.classicCar"
          name="Fahrzeug"
          auto-select-first
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Fahrzeugname"
          :query="searchFahrzeug"
          :input-formatter="fahrzeugFormatter"
          :result-formatter="fahrzeugFormatter"
          :horizontal="true"
          label="Fahrzeug"
          required
          strict
          infotext="Sie können gezielt nach einem Fahrzeugtyp bzw. -modell suchen. Die Herstellerbezeichnung muss nicht zwangsläufig eingegeben werden. Auch eine Suche nach Spitznamen ist möglich (z.B. &quot;Ente&quot; oder &quot;Bulli&quot;)."
          @hit="setFahrzeug"
          @unselect="unsetFahrzeug"
        />
        <basic-checkbox
          :value="order.carNotFound"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Fahrzeug nicht gefunden"
          @input="toggleCarNotFound"
        />
        <template v-if="order.carNotFound">
          <basic-input
            id="hersteller"
            v-model="order.classicCar.Hersteller"
            name="Hersteller"
            label="Hersteller"
            placeholder="Name des Herstellers"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            required
          />
          <basic-input
            id="modellname"
            v-model="order.classicCar.Modellname"
            name="Modellname"
            label="Modellname"
            placeholder="Modellname"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            required
          />
        </template>
        <basic-input
          v-model="order.carData.erstzulassung"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Jahr der Erstzulassung"
          :horizontal="true"
          :margin="false"
          label="Jahr der Erstzulassung"
          required
          type="number"
          :validation="{min_value: erstzulassungMin, max_value: erstzulassungMax}"
        >
          <template #slotinfotext>
            <p class="mb-0">
              Das Jahr der Erstzulassung finden Sie in der Zulassungsbescheinigung (Feld B).
              Bitte tragen Sie nur das JAHR der Erstzulassung ein (keinen Tag und Monat)
            </p>
          </template>
        </basic-input>
        <basic-input
          v-model="order.carData.motorstaerke"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          placeholder=""
          label="Motorstärke"
          required
          type="number"
          min="0"
          max="1000"
          :disabled="!$can('canAlwaysEditKw') && !order.carNotFound || !$can('editKW')"
          infotext="Nennleistung, siehe Zulassungsbescheinigung (Feld P.2)"
        >
          <template #append>
            <span class="input-group-text">kW</span>
          </template>
        </basic-input>
        <BasicCurrencyInput
          v-model="order.carData.versicherungswert"
          required
          horizontal
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          name="Versicherungswert"
          label="Versicherungswert"
          placeholder="Bitte einen Wert ohne Nachkommastellen eingeben"
          :disable-arrows="true"
          :precision="0"
          infotext="Versicherungswert ist der durch Wertgutachten oder Selbstbewertung ermittelte Wert des versicherten Fahrzeugs. Der Versicherungswert sollte sich nach dem Wiederbeschaffungswert des Fahrzeugs richten."
        />
        <Ueberpruefung
          v-if="isUeberpruefungVersicherungswert"
          field-name="Versicherungswert"
        />
        <Ueberpruefung
          v-if="isUeberpruefungPremiumCars"
        />
        <BasicSelect
          v-model="order.wiederherstellungswertVersichern"
          required
          horizontal
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          name="Versicherung Wiederherstellungswert / Wiederaufbauwert"
          label="Zusätzlich Wiederherstellungswert / Wiederaufbauwert versichern"
          placeholder="Bitte auswählen"
          :options="[
            { label: 'Ja', value: true },
            { label: 'Nein', value: false },
          ]"
          infotext="Der Wiederherstellungswert bemisst sich an den Kosten für die Beschaffung eines entsprechenden, gebrauchten Fahrzeuges zuzüglich der Kosten, die dafür entstehen, dieses Fahrzeug in den des versicherten Fahrzeuges entsprechenden Zustand zu versetzen."
        />
        <Ueberpruefung
          v-if="isUeberpruefungWiederherstellungswertVersichern"
          field-name="Zusätzlich Wiederherstellungswert / Wiederaufbauwert versichern"
        />
        <transition name="slide-fade">
          <div v-if="order.wiederherstellungswertVersichern">
            <BasicCurrencyInput
              v-model="order.wiederherstellungswert"
              horizontal
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              required
              name="Wiederherstellungs- / Wiederaufbaukosten"
              label="Wiederherstellungs- / Wiederaufbaukosten"
              placeholder="Bitte einen Wert ohne Nachkommastellen eingeben"
              :precision="0"
              infotext="Wiederherstellungs- oder Wiederaufbaukosten sind Kosten, die Sie zur Restaurierung des Fahrzeuges tatsächlich aufgewendet haben."
            />
            <BasicCurrencyInput
              :value="Number(order.carData.versicherungswert) + Number(order.wiederherstellungswert)"
              horizontal
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              required
              name="Gesamtversicherungswert"
              label="Gesamtversicherungswert"
              :precision="0"
              disabled
              infotext="Der Versicherungswert und der Wiederherstellungs-/Wiederaufbauwert zusammen bilden den Gesamtversicherungswert."
            />
          </div>
        </transition>
        <BasicSelect
          v-model="order.carData.zustand"
          required
          horizontal
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          name="Fahrzeugzustand"
          label="Fahrzeugzustand"
          placeholder="Bitte auswählen"
          :options="fahrzeugZustand"
        />
        <Ueberpruefung
          v-if="isUeberpruefungZustand"
          field-name="Fahrzeugzustand"
        />
        <!-- <basic-checkbox
          v-model="order.carData.restaurierung"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="In Restaurierung"
        /> -->
        <basic-select
          v-model="order.wkz"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Fahrzeugart"
          required
          :options="wkz"
          placeholder="Bitte auswählen"
        />
        <Hardknockout
          v-if="isHardknockoutWKZ"
          field-name="Fahrzeugart"
        />
        <Ueberpruefung
          v-if="isUeberpruefungWKZ || isUeberpruefungWKZChange"
          field-name="Fahrzeugart"
        />
      </form>
    </template>
  </ca-card>
</template>

<script>
import CaCard from '@/components/Card.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead.vue'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox.vue'

import wkz from '@/resources/enums/wkz.js'
import fahrzeugZustand from '@/resources/enums/fahrzeug-zustand.js'

import feathers from '@/api'
import enumsToSelectOptions from '@/helpers/enumToSelectOptions'

import getSegmentByEZ from '@/helpers/getSegmentByEZ'
import Hardknockout from './Hardknockout.vue'
import Ueberpruefung from './Ueberpruefung.vue'
import BasicCurrencyInput from '@/components/BaseComponents/BasicCurrencyInput.vue'
import getWKZByClassicCar from '@/resources/helpers/getWKZByClassicCar'

export default {
  inject: ['$validator'],
  name: 'HssCarForm',
  components: {
    CaCard,
    BasicInput,
    BasicSelect,
    BasicTypeahead,
    BasicCheckbox,
    Hardknockout,
    Ueberpruefung,
    BasicCurrencyInput
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    fahrzeug: '',
    getWKZByClassicCar
  }),
  computed: {
    wkz () {
      return Object.values(wkz).map(wkz => ({ label: (wkz.api === '3' ? '003' : wkz.api) + ' | ' + wkz.human, value: wkz.api }))
    },
    fahrzeugZustand () {
      return enumsToSelectOptions(fahrzeugZustand)
    },
    erstzulassungMin () {
      if (!this.order.classicCar?.BJ_von) return 1886
      return this.order.classicCar.BJ_von - 2
    },
    erstzulassungMax () {
      if (!this.order.classicCar?.BJ_bis) return 9999
      return this.order.classicCar.BJ_bis + 5
    },
    isHardknockoutWKZ () {
      if (!this.order.wkz || !this.order.carData?.erstzulassung) return false

      let invalidWKZs = ['621']
      if (invalidWKZs.includes(this.order.wkz)) return true // generell verboten

      invalidWKZs = ['3'] // eigt. 003
      if (getSegmentByEZ(this.order.carData.erstzulassung) === 'PremiumCars' && invalidWKZs.includes(this.order.wkz)) return true // für premiumCars Verboten

      let invalidSegments = ['PremiumCars', 'Newtimer']
      invalidWKZs = ['251', '351', '481', '127', '451', '581', '621', '719']
      if (invalidSegments.includes(getSegmentByEZ(this.order.carData.erstzulassung)) && invalidWKZs.includes(this.order.wkz)) return true // für PremiumCars und Newtimer verboten

      invalidSegments = ['PermiumCars', 'Newtimer', 'Youngtimer']
      invalidWKZs = ['581', '719']
      if (invalidSegments.includes(getSegmentByEZ(this.order.carData.erstzulassung)) && invalidWKZs.includes(this.order.wkz)) return true // für PremiumCars, Newtimer und Youngtimer verboten

      return false
    },
    isUeberpruefungWKZ () {
      if (getSegmentByEZ(this.order.carData.erstzulassung) === 'Oldtimer' && this.order.wkz === '719') return true
      return false
    },
    isUeberpruefungWKZChange () { // Wenn wkz geändert wird.
      if (!this.order.classicCarId || !this.order.wkz || this.order.carNotFound) return false
      if (getWKZByClassicCar(this.order.classicCar) !== this.order.wkz) return true
      return false
    },
    isUeberpruefungVersicherungswert () {
      if (!this.order.carData.versicherungswert) return false
      if (this.order.carNotFound || !this.order.classicCar) return true
      const versicherungswert = this.order.carData.versicherungswert
      if (versicherungswert >= 500000) return true
      const insuranceEvaluationValues = []
      for (let i = 1; i <= 3; i++) { // Nur Zustandsnoten 1-3 berücksichtigen
        const wert = this.order.classicCar[`Note_${i}`]
        if (wert > 0) insuranceEvaluationValues.push(wert) // fehlende Angabe (0) entfernen
      }
      if (insuranceEvaluationValues.length === 0) return true // Zustandsnoten haben keinen Gegenwert -> überprüfung
      const min = insuranceEvaluationValues[insuranceEvaluationValues.length - 1] * 0.9 // min = niedrigster Gegenwert -10%
      const max = insuranceEvaluationValues[0] * 1.1 // max = höchster Gegenwert +10%
      return !(min <= versicherungswert && versicherungswert <= max)
    },
    isUeberpruefungZustand () {
      if (!this.order.carData?.zustand) return false
      return this.order.carData?.zustand !== 'originalState'
    },
    isUeberpruefungWiederherstellungswertVersichern () {
      return this.order.wiederherstellungswertVersichern
    },
    isUeberpruefungPremiumCars () {
      if (!this.order.carData?.erstzulassung) return false
      let gesamtversicherungswert = this.order.carData.versicherungswert
      if (this.order.wiederherstellungswert) gesamtversicherungswert += this.order.wiederherstellungswert
      const invalidSegments = ['PremiumCars', 'Newtimer']
      return invalidSegments.includes(getSegmentByEZ(this.order.carData.erstzulassung)) && gesamtversicherungswert >= 100000
    },
    isSomeHardknockouts () {
      return this.isHardknockoutWKZ
    },
    isSomeUeberpruefung () {
      return this.isUeberpruefungWKZ || this.isUeberpruefungVersicherungswert || this.isUeberpruefungZustand || this.isUeberpruefungWiederherstellungswertVersichern || this.isUeberpruefungWKZChange || this.isUeberpruefungPremiumCars
    }
  },
  watch: {
    isSomeUeberpruefung: {
      handler: function (newVal) {
        this.$emit('ueberpruefung-change', newVal)
      }
    },
    isSomeHardknockouts: {
      handler: function (newVal) {
        this.$emit('hardknockout-change', newVal)
      }
    }
  },
  methods: {
    async searchFahrzeug (searchTerm) {
      // Der Suchbegriff wird in Wörter zerlegt und das Wort muss in einem der searchFields enthalten sein
      const searchTermWords = searchTerm.split(' ').filter(word => word.length > 0)
      const searchFields = ['Modellname', 'Karosserie', 'Hersteller', 'Baureihe', 'Spitzname1', 'Spitzname2', 'Spitzname3']
      const searchTermQuery = {
        $and: searchTermWords.map(word => {
          return {
            $or: searchFields.map(field => ({
              [field]: {
                $regex: word,
                $options: 'i'
              }
            }))
          }
        })
      }
      return feathers.service('hsdata-classic-data-car').find({
        query: {
          $limit: 50,
          ...searchTermQuery
        }
      })
    },
    setFahrzeug (car) {
      this.$set(this.order, 'classicCarId', car._id)
      this.$set(this.order, 'classicCar', car)
      this.$set(this.order.carData, 'motorstaerke', car.kW)
      this.$set(this.order, 'wkz', getWKZByClassicCar(car))
    },
    unsetFahrzeug () {
      this.$delete(this.order, 'classicCar')
      this.$delete(this.order, 'classicCarId')
    },
    fahrzeugFormatter (car) {
      if (car.Hersteller && car.Modellname) {
        let fahrzeugFormat = `${car.Hersteller} ${car.Modellname}`

        if (car.BJ_bis) fahrzeugFormat += ' (' + car.BJ_von + ' - ' + car.BJ_bis + ')'
        else if (car.BJ_von) fahrzeugFormat += ' (ab ' + car.BJ_von + ')'
        if (car.kW) fahrzeugFormat += ` ${car.kW}kW`
        if (car.Typ_D2_Modellreihe) fahrzeugFormat += ' / ' + car.Typ_D2_Modellreihe
        if (car.Karosserie) fahrzeugFormat += ' / ' + car.Karosserie
        if (car.Spitzname1) fahrzeugFormat += ' - auch bekannt als ' + car.Spitzname1
        if (car.Spitzname2) fahrzeugFormat += ', ' + car.Spitzname2
        if (car.Spitzname3) fahrzeugFormat += ', ' + car.Spitzname3

        return fahrzeugFormat
      }
    },
    toggleCarNotFound (isChecked) {
      if (isChecked) {
        this.$set(this.order, 'classicCar', {})
      } else {
        this.unsetFahrzeug()
      }
      this.$set(this.order, 'carNotFound', isChecked)
    }
  }
}
</script>
