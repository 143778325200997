var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{staticClass:"mb-3",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"mb-0"},[_vm._v(" Zahlungsinformationen ")])]},proxy:true},{key:"body",fn:function(){return [_c('form',[_c('basic-select',{attrs:{"columns":{
          label: ['col-12', 'col-md-4'],
          input: ['col-12', 'col-md-8'],
        },"horizontal":true,"label":"Zahlart","required":_vm.required,"options":_vm.filteredZahlarten},model:{value:(_vm.order.zahlart),callback:function ($$v) {_vm.$set(_vm.order, "zahlart", $$v)},expression:"order.zahlart"}}),(_vm.order.zahlart === 'SEPA')?[_c('BasicCheckbox',{attrs:{"columns":{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']},"horizontal":true,"label":"Abweichender Kontoinhaber"},model:{value:(_vm.order.isAbwKontoinhaber),callback:function ($$v) {_vm.$set(_vm.order, "isAbwKontoinhaber", $$v)},expression:"order.isAbwKontoinhaber"}}),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.order.isAbwKontoinhaber)?_c('div',[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('BasicInput',{attrs:{"label":"Abw. Kontoinhaber","columns":{
                    label: ['col-12', 'col-md-6'],
                    input: ['col-12', 'col-md-6'],
                  },"horizontal":true,"required":_vm.required,"placeholder":"Vorname"},model:{value:(_vm.order.abwKontoinhaber.VOR),callback:function ($$v) {_vm.$set(_vm.order.abwKontoinhaber, "VOR", $$v)},expression:"order.abwKontoinhaber.VOR"}})],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('BasicInput',{attrs:{"name":"Nachname","placeholder":"Nachname","required":_vm.required},model:{value:(_vm.order.abwKontoinhaber.NAM),callback:function ($$v) {_vm.$set(_vm.order.abwKontoinhaber, "NAM", $$v)},expression:"order.abwKontoinhaber.NAM"}})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('BasicInput',{attrs:{"label":"Adresse abw. Kontoinhaber","columns":{
                    label: ['col-12', 'col-md-6'],
                    input: ['col-12', 'col-md-6'],
                  },"horizontal":true,"name":"Straße","required":_vm.required,"placeholder":"Straße","margin":false},model:{value:(_vm.order.abwKontoinhaber.STR),callback:function ($$v) {_vm.$set(_vm.order.abwKontoinhaber, "STR", $$v)},expression:"order.abwKontoinhaber.STR"}})],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('BasicInput',{attrs:{"name":"Hausnummer","placeholder":"Nr.","required":_vm.required,"margin":false},model:{value:(_vm.order.abwKontoinhaber.HNR),callback:function ($$v) {_vm.$set(_vm.order.abwKontoinhaber, "HNR", $$v)},expression:"order.abwKontoinhaber.HNR"}})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('BasicInput',{attrs:{"name":"Postleitzahl","columns":{
                    label: ['col-12', 'col-md-6'],
                    input: ['col-12', 'col-md-6'],
                  },"horizontal":true,"required":_vm.required,"placeholder":"PLZ"},model:{value:(_vm.order.abwKontoinhaber.PLZ),callback:function ($$v) {_vm.$set(_vm.order.abwKontoinhaber, "PLZ", $$v)},expression:"order.abwKontoinhaber.PLZ"}})],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('BasicInput',{attrs:{"name":"Ort","placeholder":"Ort","required":_vm.required},model:{value:(_vm.order.abwKontoinhaber.ORT),callback:function ($$v) {_vm.$set(_vm.order.abwKontoinhaber, "ORT", $$v)},expression:"order.abwKontoinhaber.ORT"}})],1)])]):_vm._e()]),_c('BasicIbanInput',{attrs:{"required":""},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}})]:_vm._e()],2),void 0]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }