module.exports = function getWKZByClassicCar (car) {
  switch (car.Karosserie) {
  case 'PKW offen':
  case 'PKW geschlossen':
  case 'Limousine 4-türig':
  case 'Coupé 2-türig':
  case 'Cabriolet 2-türig':
  case 'Coupé':
  case 'Limousine 3-türig':
  case 'Roadster':
  case 'Cabriolet':
  case 'Cabriolimousine':
  case 'Motocoupé':
  case 'Kleinwagen':
  case 'Limousine 2-türig':
  case 'Targa':
  case 'Hardtop-Coupé':
  case 'Kombi':
  case 'Mini Bus':
  case 'Cabriolet 2-Sitze':
  case 'Coupé 3-türig':
  case 'Pullman':
  case 'Kombi 5-türig':
  case 'Fließheck-Lim. 2-türig':
  case 'Fließheck-Lim. 4-türig':
  case 'Kombi 3-türig':
  case 'Limousine 5-türig':
  case 'Hardtop-Lim. 2-türig':
  case 'Sport-Coupé':
  case 'Geländewagen':
  case 'Schrägheck-Lim. 2-türig':
  case 'Coupé 4-türig':
  case 'Hardtop-Lim. 4-türig':
  case 'Cabriolet 4-Sitze':
  case 'Kombi 2-türig':
  case 'Hardtop-Cabriolet':
  case 'Kombi (kurz)':
  case 'Kombi (lang)':
  case 'Coupé (2+2)':
  case 'Kombi-Cpé. 3-türig':
  case 'Sport-Cabrio':
  case 'Van':
  case 'Geländewagen offen':
  case 'Cabriolet 4-türig':
  case 'Schwimmwagen':
  case 'Limousine':
  case 'Hatchback-Coupé':
  case 'Notchback-Coupé':
  case 'Fließheck-Coupé':
  case 'Fließheck-Lim. 5-türig':
  case 'Runabout':
  case 'Buggy':
  case 'Stretch-Limousine':
  case 'Stufenheck-Coupé':
  case 'Geländewagen lang':
  case 'Geländewagen kurz':
  case 'Limousine 6-türig':
  case 'Kombi 4-türig':
  case 'Kombi-Cpé. 5-türig':
  case 'Hardtop-Cpé. 4-türig':
  case 'Club Coupé':
  case 'SUV':
  case 'Tourer':
    return '112' // PKW
  case 'Kabinenroller':
  case 'Roller':
  case 'Kraftrad':
  case 'Gespann':
  case 'Dreirad':
  case 'Mofa':
  case 'Mokick':
    return '3' // Kraftrad
  case 'Kastenwagen':
  case 'Kasten':
  case 'Lieferwagen':
  case 'Bus':
  case 'Transporter':
  case 'Doppelkabine':
    return '251' // Lieferwagen < 3,5t
  case 'Pickup':
  case 'Pritsche-Doka':
  case 'Pritsche':
  case 'Zugmaschine':
  case 'Pickup-Roadster':
  case 'LKW/Zgm':
  case 'Zgm.-Kipper':
    return '351' // Lastkraftwagen > 3,5t
    // case 'Zugmaschinen':
    //   return '481' // Zugmaschinen
    //   break
  case 'Wohnmobil':
  case 'Sonder-Kfz':
    return '127' // Wohnmobil
  case 'Schlepper':
  case 'Geräteträger':
    return '451' // Traktor
    // case 'Anhänger':
    //   return '581' // Anhänger
    //   break
    // case 'Omnibus':
    //   return '621' // Omnibus
    //   break
  default:
    return '719' // Sonstige
  }
}
