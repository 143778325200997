<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Ergebnis
      </h4>
    </template>
    <template
      #body
    >
      <template v-if="result">
        <ca-alert
          v-if="result.success && result.anfrage"
          variant="warning"
          message="Mit den derzeit eingegebenen Parametern kann nur eine Anfrage durchgeführt werden"
          class="mb-3"
        />
        <ca-alert
          v-if="result.success && result.ablehnung"
          variant="warning"
          message="Mit den derzeit eingegebenen Parametern kann das Fahrzeug nicht versichert werden"
          class="mb-3"
        />
        <ca-alert
          v-if="!result.success"
          variant="danger"
          :message="'Es kann kein Angebot berechnet werden. ' + result.message"
          class="mb-3"
        />
      </template>
      <Hardknockout v-if="showHardknockout" />
      <Ueberpruefung
        v-if="showUeberpruefung && !showHardknockout"
      />
      <div class="row mb-4">
        <div
          v-if="missingFields[0]"
          class="col-12"
        >
          {{ missingFields[0] }}
        </div>
        <template v-if="result && result.success">
          <div class="col-12 col-md-4">
            Prämie Netto nach Zahlungsperiode
          </div>
          <div class="col-12 col-md-8">
            {{ formatPrice(result.rateNetto) }}
          </div>
          <div class="col-12 col-md-4">
            Versicherungsteuer nach Zahlungsperiode
          </div>
          <div class="col-12 col-md-8">
            {{ formatPrice(result.versicherungssteuerZahlweise) }}
          </div>
          <div class="col-12 col-md-4">
            Prämie Brutto nach Zahlungsperiode
          </div>
          <div class="col-12 col-md-8 font-weight-bold">
            {{ formatPrice(result.rateBrutto) }}
          </div>
        </template>
        <div
          v-else
          class="col-12"
        >
          Prämie kann noch nicht berechnet werden.
        </div>
      </div>
      <template v-if="!hideDocuments">
        <a
          v-if="client.vertragsunterlagenFile"
          class="btn btn-link d-block w-100 text-primary text-left pl-0 mb-2"
          :href="vertragsunterlagenDownloadUrl"
        >
          <i class="far fa-file-download" />
          Kundeninformationen und Versicherungsbedingungen herunterladen
        </a>
        <a
          v-if="client.erstinformationenFile"
          class="btn btn-link d-block w-100 text-primary text-left pl-0 mb-2"
          :href="erstinformationenDownloadUrl"
        >
          <i class="far fa-file-download" />
          Erstinformationen herunterladen
        </a>
        <a
          v-if="client.datenschutzinformationenFile"
          class="btn btn-link d-block w-100 text-primary text-left pl-0 mb-2"
          :href="datenschutzinformationenDownloadUrl"
        >
          <i class="far fa-file-download" />
          Datenschutzinformationen herunterladen
        </a>
      </template>
      <template v-if="!hideCheckboxes">
        <BasicCheckbox
          id="contract_documents"
          v-model="order.vertragsunterlagenErhalten"
          name="Erhalt und Kenntnisnahme der Vertragsunterlagen"
          :required="required"
          label="Ich habe dem Kunden alle o.g. Informationen ausgehändigt und der Kunde hat diese zur Kenntnis genommen und akzeptiert."
          class="mb-4"
        />
        <BasicCheckbox
          id="initial_information"
          v-model="order.erstinformationenGelesen"
          name="Erstinformationen"
          :required="required"
          label="Der Kunde hat die Herzenssache-Erstinformationen gelesen und akzeptiert diese."
          class="mb-4"
        />
        <template v-if="!customer.account && $can('createCustomerAccount')">
          <BasicCheckbox
            id="createCustomerAccount"
            v-model="order.createCustomerAccount"
            name="createCustomerAccount"
            label="Kundenkontozugang erstellen"
          />
          <div
            class="col-12 pl-0"
            :class="{'col-12 col-md-6': columnLayout}"
          >
            <BasicInput
              v-if="order.createCustomerAccount"
              id="customerAccountMail"
              v-model="order.customerAccountMail"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              required
              name="E-Mail Kundenkontozugang"
              label="E-Mail Kundenkontozugang"
              placeholder="E-Mail Adresse"
              horizontal
              class="pr-4"
              type="email"
            />
          </div>
        </template>
      </template>
    </template>
  </ca-card>
</template>

<script>
import CaCard from '@/components/Card'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox'
import formatPrice from '@/filters/formatPrice.js'
import Hardknockout from './Hardknockout.vue'
import Ueberpruefung from './Ueberpruefung.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'

export default {
  inject: ['$validator'],
  components: {
    CaCard,
    BasicCheckbox,
    Hardknockout,
    Ueberpruefung,
    BasicInput
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: true
    },
    result: {
      validator: prop => typeof prop === 'object' || prop === null,
      required: true
    },
    missingFields: {
      type: Array,
      default: () => []
    },
    showUeberpruefung: {
      type: Boolean,
      default: false
    },
    showHardknockout: {
      type: Boolean,
      default: false
    },
    hideCheckboxes: {
      type: Boolean,
      default: false
    },
    hideDocuments: {
      type: Boolean,
      default: false
    },
    customer: {
      type: Object,
      default: () => {}
    },
    required: {
      type: Boolean,
      default: false
    },
    columnLayout: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    erstinformationenDownloadUrl () {
      return this.client.erstinformationenFile.downloadUrl
    },
    datenschutzinformationenDownloadUrl () {
      return this.client.datenschutzinformationenFile.downloadUrl
    },
    vertragsunterlagenDownloadUrl () {
      return this.client.vertragsunterlagenFile.downloadUrl
    }
  },
  methods: {
    formatPrice,
    downloadErstinformationen () {
      for (let i = 0; i < this.erstinformationenDownloadUrls.length; i++) {
        window.open(this.erstinformationenDownloadUrls[i])
      }
    },
    downloadVertragsunterlagen () {
      for (let i = 0; i < this.vertragsunterlagenDownloadUrls.length; i++) { window.open(this.vertragsunterlagenDownloadUrls[i]) }
    },
    downloadDatenschutzinformationen () {
      for (let i = 0; i < this.datenschutzinformationenDownloadUrls.length; i++) { window.open(this.datenschutzinformationenDownloadUrls[i]) }
    }
  }
}
</script>
