<template>
  <div>
    <ca-card class="mb-3">
      <template #header>
        <h4 class="mb-0">
          Aufschlag / Rabatt Haftpflicht
        </h4>
      </template>
      <template #body>
        <form>
          <p>Netto vor Rabatt / Aufschlag: {{ !result || !result.haftpflichtNetto ? null : formatPrice(result.haftpflichtNetto) }}</p>
          <BasicInput
            v-model.number="order.haftpflichtDiscount"
            label="Rabatt / Aufschlag in %"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            horizontal
            placeholder="Rabatt / Aufschlag in %"
            type="number"
          />
          <p>Netto nach Rabatt / Aufschlag: {{ !result || !result.haftpflichtNettoDiscounted ? null : formatPrice(result.haftpflichtNettoDiscounted) }}</p>
        </form>
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4 class="mb-0">
          Aufschlag / Rabatt Kasko
        </h4>
      </template>
      <template #body>
        <form>
          <p>Netto vor Rabatt / Aufschlag: {{ !result || !result.kaskoNetto ? null :formatPrice(result.kaskoNetto) }}</p>
          <BasicInput
            v-model.number="order.kaskoDiscount"
            label="Rabatt / Aufschlag in %"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            horizontal
            placeholder="Rabatt / Aufschlag in %"
            type="number"
          />
          <p>Netto nach Rabatt / Aufschlag: {{ !result || !result.kaskoNettoDiscounted ? null : formatPrice(result.kaskoNettoDiscounted) }}</p>
        </form>
      </template>
    </ca-card>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import BasicInput from '@/components/BaseComponents/BasicInput'
import formatPrice from '@/filters/formatPrice.js'

export default {
  inject: ['$validator'],
  components: {
    CaCard,
    BasicInput
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    result: {
      validator: prop => typeof prop === 'object' || prop === null,
      required: true
    }
  },
  methods: {
    formatPrice
  }
}
</script>
