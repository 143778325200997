<template>
  <div v-if="this.$can('processAnfragen')">
    <HSSOrderForms
      v-if="order"
      heading="HSS Anfrage bearbeiten"
      :order="order"
      :client="client"
      :customer="customer"
      :loading="loading"
      :show-validation-error="showValidationError"
      :show-product-validation-border="showProductValidationBorder"
      hide-checkboxes
      validate-angebot
      @setCustomer="setCustomer"
      @isHardknockout="isHardknockout=$event"
      @isUeberpruefung="isUeberpruefung=$event"
      @result="result = $event"
      @fillMockData="order = $event"
    >
      <template #buttons>
        <button
          type="button"
          class="btn text-primary"
          @click="abort"
        >
          Abbrechen
        </button>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="isHardknockout || pending"
          @click="save"
        >
          Angebot erstellen
          <i
            v-if="pending"
            class="fas fa-circle-notch fa-spin"
          />
        </button>
      </template>
    </HSSOrderForms>
  </div>
</template>

<script>
import feathers from '@/api'
import HSSOrderForms from '@/components/Forms/Order/HSSOrderForms.vue'
import hsOrderProducts from '@/resources/enums/hsOrderProducts'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  name: 'HSSOrderEdit',
  components: {
    HSSOrderForms
  },
  data () {
    return {
      loading: false,
      error: null,
      columnLayout: false,
      showOrderModal: false,
      order: null,
      customer: {},
      result: null,
      saveErrorMessage: null,
      showFormInvalidHint: null,
      pending: false,
      client: {},
      isHardknockout: false,
      showValidationError: false
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    showProductValidationBorder () {
      if (!this.order) return false
      return !this.order.product && this.showValidationError
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async save () {
      this.showValidationError = !await this.$validator.validateAll() || !this.order.product
      if (this.showValidationError) return
      this.pending = true
      try {
        await feathers.service('hs-order-process-anfrage-annehmen').create(this.order)
      } catch (e) {
        console.error(e)
      } finally {
        this.pending = false
        this.$router.push({ name: 'HSSOrderDetail', params: { orderId: this.order._id } })
      }
    },
    async abort () {
      this.$router.push({ name: 'HSSOrderDetail', params: { orderId: this.order._id } })
    },
    async fetchData () {
      try {
        this.loading = true
        this.order = await this.$store.dispatch('hs-order/get', this.$route.params.orderId)
        // fill in default Values in Productpackages
        const defaultValues = new this.$FeathersVuex.api.HsOrder()
        Object.values(hsOrderProducts).forEach(product => {
          if (product.api === this.order.product) return
          this.$set(this.order.productPackages, product.api, defaultValues.productPackages[product.api])
        })
        const clientReq = await this.$store.dispatch('clients/find', {
          query: {
            $limit: 1
          }
        })
        this.client = clientReq.data[0]
        this.customer = this.order.customer
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    setCustomer (newCustomer) {
      this.customer = newCustomer
    }
  }
}
</script>

<style scoped lang="scss">

.order-info {
  position: sticky;
  top: 70px;
}

</style>
