export default function getByDot (base, path) {
  const keys = path.split('.')
  let curBase = base
  for (let i = 0; i < keys.length; i++) {
    if (curBase === undefined || curBase === null) return curBase
    const key = keys[i]
    curBase = curBase[key]
  }
  return curBase
}
