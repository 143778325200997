<template>
  <div>
    <BasicInput
      v-model="modelValue.iban"
      label="IBAN"
      maxlength="22"
      :columns="{
        label: ['col-12', 'col-md-4'],
        input: ['col-12', 'col-md-8'],
      }"
      :horizontal="true"
      :required="required"
      :validation="{ iban: true }"
      placeholder="IBAN"
      :disabled="disabled"
    />
    <transition name="slide-fade">
      <div
        v-if="bank && bank.NAME && bank.BIC"
        class="form-row"
      >
        <div class="col col-md-4">
          BIC
        </div>
        <div class="col col-md-6">
          {{ bank.BIC }}
        </div>
        <div class="col col-md-4">
          Kreditinstitut
        </div>
        <div class="col col-md-6">
          {{ bank.NAME }}
        </div>
      </div>
    </transition>
  </div>
</template>
<!-- BasicIbanInput setzt Iban, bic, blz und bankname in das Object, das als v-model übergeben wird -->
<script>
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import debounce from 'lodash.debounce'
import iban from 'iban'

export default {
  components: {
    BasicInput
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    bank: null
  }),
  computed: {
    modelValue: {
      get () { return this.value },
      set (modelValue) { this.$emit('input', modelValue) }
    }
  },
  watch: {
    modelValue: {
      handler (newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    'modelValue.iban': {
      handler () {
        if (this.modelValue.iban) { this.$set(this.modelValue, 'iban', this.modelValue.iban.toUpperCase()) }
        this.loadBankDetails()
      },
      immediate: true
    }
  },
  methods: {
    debouncedLoadBankData: debounce(async function (blz) {
      const bankDetails = (await this.$store.dispatch('credit-institution/find', {
        query: {
          BLZ: blz,
          BIC: { $ne: null }
        }
      })).data[0]
      this.bank = bankDetails
      this.$set(this.modelValue, 'bic', bankDetails.BIC)
      this.$set(this.modelValue, 'blz', bankDetails.BLZ)
      this.$set(this.modelValue, 'bankname', bankDetails.NAME)
    }, 500),
    async loadBankDetails () {
      this.bank = null
      if (!this.modelValue.iban || !iban.isValid(this.modelValue.iban)) {
        this.$delete(this.modelValue, 'bic')
        this.$delete(this.modelValue, 'blz')
        this.$delete(this.modelValue, 'bankname')
      } else {
        const blz = this.modelValue.iban.substr(4, 8)
        this.debouncedLoadBankData(blz)
      }
    }
  }
}
</script>
