<template>
  <ca-card class="mb-3">
    <template #header>
      <div>
        <h4 class="mb-0 d-inline">
          Produkt
        </h4>
        <span
          v-if="showValidationMessage"
          class="text-danger float-right"
        >Bitte Produkt auswählen.</span>
      </div>
    </template>
    <template #body>
      <form>
        <!-- Nur übergangsweise auskommentiert -->
        <!-- <BasicInput
          v-model="order.rabatt"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          horizontal
          :margin="false"
          class="mb-4"
          label="Rabattcode"
        /> -->
        <div class="row">
          <div class="col-12">
            <div class="card mb-4">
              <div class="card-header d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <h5 class="mb-0 mr-2">
                    KFZ Haftpflicht und Teilkasko
                  </h5>
                  <span class="badge badge-primary">
                    {{ hsOrderProductsEnum.KOMPAKT.human }}
                  </span>
                </div>
                <span
                  v-if="prices && prices.KOMPAKT"
                >
                  <span class="font-weight-bold">{{ formatCurrency(prices.KOMPAKT.rateBrutto) }} {{ getZahlweise(order.zahlweise) }}</span>
                </span>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <BasicSelect
                      id="kompaktSelbstbehalt"
                      v-model="order.productPackages.KOMPAKT.selbstbehalt"
                      label="Selbstbehalt"
                      :options="selbstbehaltTKOptions"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <BasicSwitch
                      v-model="order.productPackages.KOMPAKT.schutzpacketFahrerschutz"
                      label="Schutzpaket Fahrerschutz (optional)"
                      :disabled="disabledFahrerschutz"
                      infotext="Die Fahrerschutzversicherung bietet finanzielle Sicherheit für den berechtigten Fahrer, wenn dieser bei einem selbst- oder mitverschuldeten Unfall einen Personenschaden erleidet."
                    />
                  </div>
                </div>
                <Ueberpruefung v-if="isUeberpruefungSelbstbehalt && order.product === hsOrderProductsEnum.KOMPAKT.api" />
                <Hardknockout
                  v-if="isHardknockoutSelbstbehalt && order.product === hsOrderProductsEnum.KOMPAKT.api"
                  message="Der Selbstbehalt muss kleiner sein als der Versicherungswert (ggf. zzgl. Wiederherstellungskosten) sein. Bitte wählen Sie einen geringeren Selbstbehalt aus."
                />
                <div class="row">
                  <div class="col">
                    <template v-if="prices && prices.KOMPAKT">
                      Netto: {{ formatCurrency(prices.KOMPAKT.rateNetto) }} {{ getZahlweise(order.zahlweise) }}<br>
                      Brutto: {{ formatCurrency(prices.KOMPAKT.rateBrutto) }} {{ getZahlweise(order.zahlweise) }}
                    </template>
                  </div>
                  <div class="col-auto">
                    <button
                      class="btn"
                      :class="{'btn-gray-2 text-primary': order.product !== hsOrderProductsEnum.KOMPAKT.api, 'btn-primary': order.product === hsOrderProductsEnum.KOMPAKT.api}"
                      type="button"
                      @click="$set(order, 'product', hsOrderProductsEnum.KOMPAKT.api)"
                    >
                      <span v-if="order.product !== hsOrderProductsEnum.KOMPAKT.api">Produkt {{ hsOrderProductsEnum.KOMPAKT.human }} auswählen</span>
                      <span v-else>Ausgewählt <i class="fas fa-check" /></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-4">
              <div class="card-header d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <h5 class="mb-0 mr-2">
                    KFZ Haftpflicht und Vollkasko
                  </h5>
                  <span class="badge badge-primary">
                    {{ hsOrderProductsEnum.KOMFORT.human }}
                  </span>
                </div>
                <div class="text-right">
                  <p
                    v-if="prices && prices.KOMFORT"
                    class="mb-0"
                  >
                    <span class="font-weight-bold">{{ formatCurrency(prices.KOMFORT.rateBrutto) }} {{ getZahlweise(order.zahlweise) }}</span>
                  </p>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <BasicSelect
                      id="weltweiterVersicherungsschutzKasko"
                      v-model="order.productPackages.KOMFORT.weltweiterVersicherungsschutzKasko"
                      label="Weltweiter Versicherungsschutz Kasko"
                      :options="weltweiterVersicherungsschutzBausteine"
                      infotext="Wir erweitern den Geltungsbereich der Kaskoversicherung (vgl. A 2.4. AKB) auf weltweiten Versicherungsschutz im Rahmen der vereinbarten Kaskoversicherung."
                    />
                    <BasicSelect
                      id="comfortFreeDriving"
                      v-model="order.productPackages.KOMFORT.zusatzpaketFreiesFahren"
                      label="Zusatzpaket Freies Fahren"
                      :options="zusatzpaketFreiesFahrenBausteine"
                      infotext="Erweitert den Vollkasko- oder MultiRisk-Deckungsschutz bei freien Fahrten auf Rennstrecken oder bei Track Days. Rennveranstaltungen, bei denen es auf die Erzielung einer Höchstgeschwindigkeit ankommt, sind generell vom Versicherungsschutz ausgeschlossen und nicht versicherbar."
                    />
                    <BasicSelect
                      id="komfortSelbstbehalt"
                      v-model="order.productPackages.KOMFORT.selbstbehalt"
                      label="Selbstbehalt"
                      :options="selbstbehaltVKOptions"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <BasicSwitch
                      id="schutzpacketFahrerschutz"
                      v-model="order.productPackages.KOMFORT.schutzpacketFahrerschutz"
                      label="Schutzpaket Fahrerschutz (optional)"
                      :disabled="disabledFahrerschutz"
                      infotext="Die Fahrerschutzversicherung bietet finanzielle Sicherheit für den berechtigten Fahrer, wenn dieser bei einem selbst- oder mitverschuldeten Unfall einen Personenschaden erleidet."
                    />
                    <BasicSwitch
                      id="zusatzpaketPlus"
                      v-model="order.productPackages.KOMFORT.plusVolllasko"
                      name="Zusatzpaket Plus"
                      label="Zusatzpaket &quot;Plus&quot; (optional)"
                    >
                      <template #slotinfotext>
                        <p class="mb-0">
                          <span class="font-weight-bold">Eigenschadenschutz</span><br>
                          Versicherungsschutz besteht im Rahmen der KFZ-Haftpflichtversicherung für Sachschäden, die von Ihnen oder einer mitversicherten Person durch den Gebrauch des versicherten Pkw an anderen auf Sie zugelassenen Kraftfahrzeugen, Ihnen gehörenden Gebäuden oder Ihren sonstigen Sachen verursacht werden.<br><br>
                          <span class="font-weight-bold">Schäden an gelagerten Fahrzeugteilen / Ersatzteilen</span><br>
                          Versichert gelten Schäden an gelagerten Fahrzeug- und Ersatzteilen für das bei uns versicherte Fahrzeug gegen die Gefahren Brand, Blitzschlag, Explosion, Leitungswasser, Einbruchdiebstahl und Sturm.<br><br>
                          <span class="font-weight-bold">Tierbissfolgeschäden</span><br>
                          Es gelten Tierbissfolgeschäden am Fahrzeug bis zu 10.000 € je Schadenfall versichert.<br><br>
                          <span class="font-weight-bold">Kurzschlussfolgeschäden</span><br>
                          Es gelten Kurzschlussfolgeschäden am Fahrzeug bis zu 10.000 € je Schadenfall versichert.<br>
                          Erhöhung der Schlüsselverlust Leistungsgrenze: Die maximale Entschädigung wird von 1.500 € auf 3.000 € je Schadenfall angehoben.
                        </p>
                      </template>
                    </BasicSwitch>
                    <!-- <BasicSwitch
                      id="zusatzpacketElektro"
                      v-model="order.productPackages.KOMFORT.zusatzpacketElektro"
                      label="Zusatzpaket Elektro (Old-/Youngtimer)"
                    /> -->
                  </div>
                  <Ueberpruefung v-if="isUeberpruefungSelbstbehalt && order.product === hsOrderProductsEnum.KOMFORT.api" />
                  <Hardknockout
                    v-if="isHardknockoutSelbstbehalt && order.product === hsOrderProductsEnum.KOMFORT.api"
                    message="Der Selbstbehalt muss kleiner sein als der Versicherungswert (ggf. zzgl. Wiederherstellungskosten) sein. Bitte wählen Sie einen geringeren Selbstbehalt aus."
                  />
                  <Ueberpruefung
                    v-if="isUeberpruefungFreiesFahren && order.product === hsOrderProductsEnum.KOMFORT.api"
                    field-name="Zusatzpaket Freies Fahren"
                  />
                </div>
                <div class="row">
                  <div class="col">
                    <template v-if="prices && prices.KOMFORT">
                      Netto: {{ formatCurrency(prices.KOMFORT.rateNetto) }} {{ getZahlweise(order.zahlweise) }}<br>
                      Brutto: {{ formatCurrency(prices.KOMFORT.rateBrutto) }} {{ getZahlweise(order.zahlweise) }}
                    </template>
                  </div>
                  <div class="col-auto">
                    <button
                      class="btn"
                      :class="{'btn-gray-2 text-primary': order.product !== hsOrderProductsEnum.KOMFORT.api, 'btn-primary': order.product === hsOrderProductsEnum.KOMFORT.api}"
                      type="button"
                      @click="$set(order, 'product', hsOrderProductsEnum.KOMFORT.api)"
                    >
                      <span v-if="order.product !== hsOrderProductsEnum.KOMFORT.api">Produkt {{ hsOrderProductsEnum.KOMFORT.human }} auswählen</span>
                      <span v-else>Ausgewählt <i class="fas fa-check" /></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <h5 class="mb-0 mr-2">
                    KFZ Haftpflicht und MultiRisk
                  </h5>
                  <span class="badge badge-primary">
                    {{ hsOrderProductsEnum.EXCLUSIV.human }}
                  </span>
                </div>
                <div class="text-right">
                  <p
                    v-if="prices && prices.EXCLUSIV"
                    class="mb-0"
                  >
                    <span class="font-weight-bold">{{ formatCurrency(prices.EXCLUSIV.rateBrutto) }} {{ getZahlweise(order.zahlweise) }}</span>
                  </p>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <BasicSelect
                      id="exclusivWorldwideProtection"
                      v-model="order.productPackages.EXCLUSIV.weltweiterVersicherungsschutzKasko"
                      label="Weltweiter Versicherungsschutz Kasko"
                      :options="weltweiterVersicherungsschutzBausteine"
                      infotext="Wir erweitern den Geltungsbereich der Kaskoversicherung (vgl. A 2.4. AKB) auf weltweiten Versicherungsschutz im Rahmen der vereinbarten Kaskoversicherung."
                    />
                    <BasicSelect
                      id="exclusivFreeDriving"
                      v-model="order.productPackages.EXCLUSIV.zusatzpaketFreiesFahren"
                      label="Zusatzpaket Freies Fahren"
                      :options="zusatzpaketFreiesFahrenBausteine"
                      infotext="Erweitert den Vollkasko- oder MultiRisk-Deckungsschutz bei freien Fahrten auf Rennstrecken oder bei Track Days. Rennveranstaltungen, bei denen es auf die Erzielung einer Höchstgeschwindigkeit ankommt, sind generell vom Versicherungsschutz ausgeschlossen und nicht versicherbar."
                    />
                    <BasicSelect
                      id="exclusivSelbstbehalt"
                      v-model="order.productPackages.EXCLUSIV.selbstbehalt"
                      label="Selbstbehalt"
                      :options="selbstbehaltVKOptions"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <BasicSwitch
                      id="exclusivDriverProtection"
                      v-model="order.productPackages.EXCLUSIV.schutzpacketFahrerschutz"
                      label="Schutzpaket Fahrerschutz (optional)"
                      :disabled="disabledFahrerschutz"
                      infotext="Die Fahrerschutzversicherung bietet finanzielle Sicherheit für den berechtigten Fahrer, wenn dieser bei einem selbst- oder mitverschuldeten Unfall einen Personenschaden erleidet."
                    />
                    <BasicSwitch
                      id="exclusivPlus"
                      v-model="order.productPackages.EXCLUSIV.plusVolllasko"
                      name="Zusatzpaket Plus"
                      label="Zusatzpaket &quot;Plus&quot; (optional)"
                    >
                      <template #slotinfotext>
                        <p class="mb-0">
                          <span class="font-weight-bold">Eigenschadenschutz</span><br>
                          Versicherungsschutz besteht im Rahmen der KFZ-Haftpflichtversicherung für Sachschäden, die von Ihnen oder einer mitversicherten Person durch den Gebrauch des versicherten Pkw an anderen auf Sie zugelassenen Kraftfahrzeugen, Ihnen gehörenden Gebäuden oder Ihren sonstigen Sachen verursacht werden.<br><br>
                          <span class="font-weight-bold">Schäden an gelagerten Fahrzeugteilen / Ersatzteilen</span><br>
                          Versichert gelten Schäden an gelagerten Fahrzeug- und Ersatzteilen für das bei uns versicherte Fahrzeug gegen die Gefahren Brand, Blitzschlag, Explosion, Leitungswasser, Einbruchdiebstahl und Sturm.<br><br>
                          <span class="font-weight-bold">Tierbissfolgeschäden</span><br>
                          Es gelten Tierbissfolgeschäden am Fahrzeug bis zu 10.000 € je Schadenfall versichert.<br><br>
                          <span class="font-weight-bold">Kurzschlussfolgeschäden</span><br>
                          Es gelten Kurzschlussfolgeschäden am Fahrzeug bis zu 10.000 € je Schadenfall versichert.<br>
                          Erhöhung der Schlüsselverlust Leistungsgrenze: Die maximale Entschädigung wird von 1.500 € auf 3.000 € je Schadenfall angehoben.
                        </p>
                      </template>
                    </BasicSwitch>
                    <!-- <BasicSwitch
                      id="exclusivElectro"
                      v-model="order.productPackages.EXCLUSIV.zusatzpacketElektro"
                      label="Zusatzpaket Elektro (Old-/Youngtimer)"
                    /> -->
                  </div>
                  <Ueberpruefung v-if="isUeberpruefungSelbstbehalt && order.product === hsOrderProductsEnum.EXCLUSIV.api" />
                  <Hardknockout
                    v-if="isHardknockoutSelbstbehalt && order.product === hsOrderProductsEnum.EXCLUSIV.api"
                    message="Der Selbstbehalt muss kleiner sein als der Versicherungswert (ggf. zzgl. Wiederherstellungskosten) sein. Bitte wählen Sie einen geringeren Selbstbehalt aus."
                  />
                  <Ueberpruefung
                    v-if="isUeberpruefungFreiesFahren && order.product === hsOrderProductsEnum.EXCLUSIV.api"
                    field-name="Zusatzpaket Freies Fahren"
                  />
                </div>
                <div class="row">
                  <div class="col">
                    <template v-if="prices && prices.EXCLUSIV">
                      Netto: {{ formatCurrency(prices.EXCLUSIV.rateNetto) }} {{ getZahlweise(order.zahlweise) }}<br>
                      Brutto: {{ formatCurrency(prices.EXCLUSIV.rateBrutto) }} {{ getZahlweise(order.zahlweise) }}
                    </template>
                  </div>
                  <div class="col-auto">
                    <button
                      class="btn"
                      :class="{'btn-gray-2 text-primary': order.product !== hsOrderProductsEnum.EXCLUSIV.api, 'btn-primary': order.product === hsOrderProductsEnum.EXCLUSIV.api}"
                      type="button"
                      @click="$set(order, 'product', hsOrderProductsEnum.EXCLUSIV.api)"
                    >
                      <span v-if="order.product !== hsOrderProductsEnum.EXCLUSIV.api">Produkt {{ hsOrderProductsEnum.EXCLUSIV.human }} auswählen</span>
                      <span v-else>Ausgewählt <i class="fas fa-check" /></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
  </ca-card>
</template>

<script>
import CaCard from '@/components/Card'
// import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import formatCurrency from '@/helpers/formatCurrency'

import fahrerkreise from '@/resources/enums/fahrerkreise.js'
import hsOrderProductsEnum from '@/resources/enums/hsOrderProducts.js'
import selbstbehaltTK from '@/resources/enums/selbstbehaltTK.js'
import selbstbehaltVK from '@/resources/enums/selbstbehaltVK.js'
import BasicSwitch from '@/components/BaseComponents/BasicSwitch.vue'
import zahlweisen from '@/resources/enums/zahlweisen.js'
import Ueberpruefung from './Ueberpruefung.vue'
import weltweiterVersicherungsschutzBausteine from '@/resources/enums/weltweiterVersicherungsschutzBausteine.js'
import zusatzpaketFreiesFahrenBausteine from '@/resources/enums/zusatzpaketFreiesFahrenBausteine.js'
import Hardknockout from './Hardknockout.vue'
import getSegmentByEZ from '@/helpers/getSegmentByEZ'
import segmente from '@/resources/enums/segmente'
import wkz from '@/resources/enums/wkz'

export default {
  inject: ['$validator'],
  components: {
    CaCard,
    // BasicInput,
    BasicSelect,
    BasicSwitch,
    Ueberpruefung,
    Hardknockout
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    prices: {
      type: Object,
      default: null
    },
    showValidationMessage: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    hsOrderProductsEnum
  }),
  computed: {
    fahrerkreise () {
      return Object.values(fahrerkreise).map(fahrerkreis => ({ label: fahrerkreis.human, value: fahrerkreis.api }))
    },
    orderProductsOptions () {
      return Object.values(hsOrderProductsEnum).map(orderProduct => ({ label: orderProduct.human, value: orderProduct.api }))
    },
    selbstbehaltTKOptions () {
      return Object.values(selbstbehaltTK).map(selbstbehalt => ({ label: selbstbehalt.human, value: selbstbehalt.api }))
    },
    selbstbehaltVKOptions () {
      return Object.values(selbstbehaltVK).map(selbstbehalt => ({ label: selbstbehalt.human, value: selbstbehalt.api }))
    },
    weltweiterVersicherungsschutzBausteine () {
      return Object.values(weltweiterVersicherungsschutzBausteine).map(baustein => ({ label: baustein.human, value: baustein.api }))
    },
    zusatzpaketFreiesFahrenBausteine () {
      return Object.values(zusatzpaketFreiesFahrenBausteine).map(baustein => ({ label: baustein.human, value: baustein.api }))
    },
    segment () {
      return getSegmentByEZ(this.order.carData.erstzulassung)
    },
    // Hardknockouts und Überprüfungen
    isUeberpruefungSelbstbehalt () {
      if (!this.order.product) return false
      const product = this.order.product
      if (product === hsOrderProductsEnum.KOMPAKT.api && this.order.productPackages.KOMPAKT.selbstbehalt) {
        const tk = Object.values(selbstbehaltTK).find(selbstbehalt => selbstbehalt.api === this.order.productPackages.KOMPAKT.selbstbehalt).tk
        if (tk >= 25000) return true
        return false
      }
      if (product === hsOrderProductsEnum.KOMFORT.api && this.order.productPackages.KOMFORT.selbstbehalt) {
        const tk = Object.values(selbstbehaltVK).find(selbstbehalt => selbstbehalt.api === this.order.productPackages.KOMFORT.selbstbehalt).tk
        const vk = Object.values(selbstbehaltVK).find(selbstbehalt => selbstbehalt.api === this.order.productPackages.KOMFORT.selbstbehalt).vk
        if (tk >= 25000 || vk >= 25000) return true
        return false
      }
      if (product === hsOrderProductsEnum.EXCLUSIV.api && this.order.productPackages.EXCLUSIV.selbstbehalt) {
        const tk = Object.values(selbstbehaltVK).find(selbstbehalt => selbstbehalt.api === this.order.productPackages.EXCLUSIV.selbstbehalt).tk
        const vk = Object.values(selbstbehaltVK).find(selbstbehalt => selbstbehalt.api === this.order.productPackages.EXCLUSIV.selbstbehalt).vk
        if (tk >= 25000 || vk >= 25000) return true
        return false
      }
      return false
    },
    isHardknockoutSelbstbehalt () {
      if (!this.order.product) return false
      const product = this.order.product
      if (product === hsOrderProductsEnum.KOMPAKT.api && this.order.productPackages.KOMPAKT.selbstbehalt) {
        const tk = Object.values(selbstbehaltTK).find(selbstbehalt => selbstbehalt.api === this.order.productPackages.KOMPAKT.selbstbehalt).tk
        let sum = this.order.carData.versicherungswert
        if (this.order.wiederherstellungswert) sum += this.order.wiederherstellungswert
        if (tk >= sum) return true
        return false
      }
      if (product === hsOrderProductsEnum.KOMFORT.api && this.order.productPackages.KOMFORT.selbstbehalt) {
        const tk = Object.values(selbstbehaltVK).find(selbstbehalt => selbstbehalt.api === this.order.productPackages.KOMFORT.selbstbehalt).tk
        const vk = Object.values(selbstbehaltVK).find(selbstbehalt => selbstbehalt.api === this.order.productPackages.KOMFORT.selbstbehalt).vk
        let sum = this.order.carData.versicherungswert
        if (this.order.wiederherstellungswert) sum += this.order.wiederherstellungswert
        if (tk >= sum || vk >= sum) return true
        return false
      }
      if (product === hsOrderProductsEnum.EXCLUSIV.api && this.order.productPackages.EXCLUSIV.selbstbehalt) {
        const tk = Object.values(selbstbehaltVK).find(selbstbehalt => selbstbehalt.api === this.order.productPackages.EXCLUSIV.selbstbehalt).tk
        const vk = Object.values(selbstbehaltVK).find(selbstbehalt => selbstbehalt.api === this.order.productPackages.EXCLUSIV.selbstbehalt).vk
        let sum = this.order.carData.versicherungswert
        if (this.order.wiederherstellungswert) sum += this.order.wiederherstellungswert
        if (tk >= sum || vk >= sum) return true
        return false
      }
      return false
    },
    isUeberpruefungFreiesFahren () {
      if (!this.order.product) return false
      const product = this.order.product
      if (product === hsOrderProductsEnum.KOMFORT.api) {
        return this.order.productPackages.KOMFORT.zusatzpaketFreiesFahren !== 'no'
      }
      if (product === hsOrderProductsEnum.EXCLUSIV.api) {
        return this.order.productPackages.EXCLUSIV.zusatzpaketFreiesFahren !== 'no'
      }
      return false
    },
    isSomeUeberpruefung () {
      return this.isUeberpruefungSelbstbehalt || this.isUeberpruefungFreiesFahren
    },
    isSomeHardknockouts () {
      return this.isHardknockoutSelbstbehalt
    },
    disabledFahrerschutz () {
      if (!this.order.wkz) return false
      return !wkz[this.order.wkz].allowFahrerschutz
    }
  },
  watch: {
    isSomeUeberpruefung: {
      handler: function (newVal) {
        this.$emit('ueberpruefung-change', newVal)
      }
    },
    isSomeHardknockouts: {
      handler: function (newVal) {
        this.$emit('hardknockout-change', newVal)
      }
    },
    segment: {
      handler: function (newVal) {
        if (newVal === segmente.PC.human) {
          this.order.productPackages.KOMPAKT.selbstbehalt = selbstbehaltTK.TK2500.api
          this.order.productPackages.KOMFORT.selbstbehalt = selbstbehaltVK.TK2500VK2500.api
          this.order.productPackages.EXCLUSIV.selbstbehalt = selbstbehaltVK.TK2500VK2500.api
        }
      }
    },
    disabledFahrerschutz: {
      handler: function (newVal) {
        if (newVal) {
          this.order.productPackages.KOMPAKT.schutzpacketFahrerschutz = false
          this.order.productPackages.KOMFORT.schutzpacketFahrerschutz = false
          this.order.productPackages.EXCLUSIV.schutzpacketFahrerschutz = false
        }
      }
    }
  },
  methods: {
    getZahlweise (curZahlweise) {
      if (!curZahlweise) return
      const humanZahlweise = Object.values(zahlweisen).find(zahlweise => zahlweise.api === curZahlweise).human
      return humanZahlweise
    },
    formatCurrency,
    getExtraPrice (val1, val2) {
      if (isNaN(val1) && !isNaN(val2)) {
        return formatCurrency(val2)
      } else if (!isNaN(val1) && isNaN(val2)) {
        return formatCurrency(val1)
      } else if (!isNaN(val1) && !isNaN(val2)) {
        return formatCurrency(val1 + val2)
      } else {
        return null
      }
    }
  }
}
</script>
