<template>
  <div>
    <ca-header
      :heading="heading"
      :column-layout="columnLayout"
      show-column-layout
      :loading="loading"
      @columnLayout="columnLayout = $event"
    >
      <template #alert>
        <ca-alert
          v-if="!!saveErrorMessage"
          variant="danger"
          :message="saveErrorMessage"
          @close="saveErrorMessage = null"
        />
        <ca-alert
          v-if="showFormInvalidHint"
          variant="info"
          message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
          @close="showFormInvalidHint = null"
        />
      </template>
      <template #customLoading>
        <ca-alert
          variant="info"
          spin-icon
          rounded
          icon="circle-notch"
          message="Lade Antragsdaten..."
        />
      </template>
    </ca-header>
    <button
      v-if="$can('seePolicyDebugInformation')"
      @click="fillMockData()"
    >
      Fill Mockdata
    </button>
    <form>
      <div class="container-fluid px-4 pt-4">
        <div
          v-if="!loading"
          class="row"
        >
          <div
            class="col-12"
            :class="{'col-xl-8 col-xxl-6': !columnLayout}"
          >
            <div class="row">
              <div
                class="col-12"
                :class="{ 'col-lg-6': columnLayout }"
              >
                <HSSCarForm
                  class="mb-3"
                  :order="order"
                  @ueberpruefung-change="$set(ueberpruefungen, 'carForm', $event)"
                  @hardknockout-change="$set(hardknockouts, 'carForm', $event)"
                />
              </div>
              <div
                class="col-12"
                :class="{ 'col-lg-6': columnLayout }"
              >
                <HSSOtherCalculationForm
                  class="mb-3"
                  :order="order"
                  :customer="customer"
                  @ueberpruefung-change="$set(ueberpruefungen, 'otherCalculationForm', $event)"
                  @hardknockout-change="$set(hardknockouts, 'otherCalculationForm', $event)"
                />
              </div>
              <div
                class="col-12"
                :class="{ 'col-lg-6': columnLayout }"
              >
                <HSSProductForm
                  class="mb-3"
                  :class="{'border border-danger': showProductValidationBorder}"
                  :order="order"
                  :prices="prices"
                  :show-validation-message="showProductValidationBorder"
                  @ueberpruefung-change="$set(ueberpruefungen, 'productForm', $event)"
                  @hardknockout-change="$set(hardknockouts, 'productForm', $event)"
                />
              </div>
              <div
                class="col-12 "
                :class="{ 'col-lg-6': columnLayout }"
              >
                <OwnerForm
                  v-if="!isNachtrag"
                  :order="order"
                  @setCustomer="$emit('setCustomer', $event)"
                  @ueberpruefung-change="$set(ueberpruefungen, 'ownerForm', $event)"
                  @hardknockout-change="$set(hardknockouts, 'ownerForm', $event)"
                />
              </div>
              <slot name="earlyOrderEnd" />
              <div
                class="col-12"
                :class="{ 'col-lg-6': columnLayout }"
              >
                <HSSOtherForm
                  class="mb-3"
                  :order="order"
                  :customer="customer"
                  :result="result"
                  :is-nachtrag="isNachtrag"
                  :required="!validateAngebot"
                  @hardknockout-change="$set(hardknockouts, 'otherForm', $event)"
                />
              </div>
              <div
                class="col-12"
                :class="{ 'col-lg-6': columnLayout }"
              >
                <PaymentInformationForm
                  :order="order"
                  :required="!validateAngebot"
                />
              </div>
              <div
                v-if="$can('giveDiscount')"
                class="col-12"
                :class="{ 'col-lg-6': columnLayout }"
              >
                <HSSRabattForm
                  class="mb-3"
                  :order="order"
                  :customer="customer"
                  :result="result"
                />
              </div>
              <slot
                :columnLayout="columnLayout"
                name="body"
              />
            </div>
            <HSSResultForm
              :customer="customer"
              class="mb-3"
              :order="order"
              :result="result"
              :client="client"
              :missing-fields="missingFields"
              :show-hardknockout="isHardknockout"
              :show-ueberpruefung="(isUeberpruefung && !isHardknockout) || customer.neuabschlussGesperrt"
              :hide-checkboxes="hideCheckboxes"
              :hide-documents="hideDocuments"
              :required="!validateAngebot"
              :column-layout="columnLayout"
              :ueberpruefung-message="ueberpruefungMessage"
            />
            <p class="mr-2 mb-0">
              *Pflichtfeld
            </p>
            <p
              v-if="showValidationError"
              class="text-danger text-right"
            >
              Bitte überprüfe deine Angaben.
            </p>
            <div
              class="form-row justify-content-end align-items-center"
            >
              <slot name="buttons" />
            </div>
          </div>
          <dev-info :data="{order,result}" />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import CaHeader from '@/components/Layout/Header.vue'
import HSSCarForm from './HSSCarForm.vue'
import HSSOtherCalculationForm from './HSSOtherCalculationForm.vue'
import HSSProductForm from './HSSProductForm.vue'
import OwnerForm from './OwnerForm.vue'
import HSSOtherForm from './HSSOtherForm.vue'
import HSSRabattForm from './HSSRabattForm.vue'
import HSSResultForm from './HSSResultForm.vue'
import getHsCalculationMissingFields from '@/helpers/getHsCalculationMissingFields'
import feathersClient from '@/api'
import DevInfo from '@/components/DevInfo.vue'
import hsOrderProducts from '@/resources/enums/hsOrderProducts'
import hsOrderTypes from '@/resources/enums/hsOrderTypes'
import PaymentInformationForm from './PaymentInformationForm.vue'
export default {
  components: { CaHeader, HSSCarForm, HSSOtherCalculationForm, HSSProductForm, OwnerForm, HSSOtherForm, HSSRabattForm, HSSResultForm, DevInfo, PaymentInformationForm },
  props: {
    order: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      default: null
    },
    showProductValidationBorder: {
      type: Boolean,
      default: false
    },
    client: {
      type: Object,
      required: true
    },
    showValidationError: {
      type: Boolean,
      default: false
    },
    isNachtrag: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: 'HSS Antrag'
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideCheckboxes: {
      type: Boolean,
      default: false
    },
    hideDocuments: {
      type: Boolean,
      default: false
    },
    validateAngebot: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      prices: null,
      result: null,
      missingFields: [],
      hardknockouts: {},
      ueberpruefungen: {},
      saveErrorMessage: null,
      showFormInvalidHint: null,
      columnLayout: true,
      hsOrderTypes
    }
  },
  computed: {
    isHardknockout () {
      return Object.values(this.hardknockouts).includes(true)
    },
    isUeberpruefung () {
      return Object.values(this.ueberpruefungen).includes(true)
    },
    ueberpruefungMessage () {
      return 'Gern überprüfen wir die Versicherbarkeit als Liebhaberfahrzeug. Nach Eingabe aller benötigten Informationen können Sie uns hierzu eine Anfrage senden.'
    }
  },
  watch: {
    order: {
      handler: function () {
        this.calculate()
      },
      deep: true,
      immediate: true
    },
    isHardknockout: {
      handler: function (newVal) {
        this.$emit('isHardknockout', newVal)
      }
    },
    isUeberpruefung: {
      handler: function (newVal) {
        this.$emit('isUeberpruefung', newVal)
      }
    }
  },
  methods: {
    async calculate () {
      this.missingFields = getHsCalculationMissingFields(this.order)
      // TODO: Die missingFields für den User als Feedback nutzen, was für den Preis noch benötigt wird
      if (this.missingFields.length > 0) return
      const calculatedPrices = await feathersClient
        .service('hs-insurance-calculate-all')
        .create(this.order)
      Object.values(hsOrderProducts).forEach(product => {
        if (calculatedPrices[product.api] && calculatedPrices[product.api].hardknockouts && calculatedPrices[product.api].hardknockouts.length > 0) { // es wird davon aus gegangen, dass Hardknockouts für alle gelten weshalb nur das erste Produkte kontrolliert wird.
          console.error(calculatedPrices[product.api].hardknockouts)
          this.result = null
          this.prices = null
        }
      })
      if (this.order.product) {
        this.$emit('result', calculatedPrices[this.order.product])
        this.result = calculatedPrices[this.order.product]
      }
      this.prices = calculatedPrices
    },
    fillMockData () {
      this.$emit('fillMockData', {
        owner: {
          title: {},
          country: {},
          city: {}
        },
        abwKontoinhaber: {},
        anfrage: false,
        ablehnung: false,
        carData: {
          erstzulassung: '1950',
          versicherungswert: 400000,
          zustand: 'originalState',
          laufleistung: 2500,
          abstellplatz: 1,
          nutzung: 1,
          kennzeichenart: 1,
          fahrzeugAuswahl: 'insuranceNewCar',
          kilometerstand: '1',
          kilometerstand_datum: '2022-01-31T23:00:00.000Z'
        },
        classicCar: null,
        productPackages: {
          KOMFORT: {
            weltweiterVersicherungsschutzKasko: 'no',
            zusatzpaketFreiesFahren: 'no',
            selbstbehalt: 'TK150VK500'
          },
          EXCLUSIV: {
            weltweiterVersicherungsschutzKasko: 'no',
            zusatzpaketFreiesFahren: 'no',
            selbstbehalt: 'TK150VK500'
          },
          KOMPAKT: {
            selbstbehalt: 'TK150'
          }
        },
        __isTemp: true,
        wertnachweis: 'detailed_appraisal',
        wiederherstellungswertVersichern: false,
        wkz: '3',
        abstellplatzPlz: '24937',
        fahrerkreis: 1,
        geburtsdatumFahrerkreisMax: '1995-11-30T23:00:00.000Z',
        vorschaeden: false,
        zahlweise: 112,
        zahlart: 'Rechnung',
        product: 'KOMFORT',
        wertnachweisDatum: '2022-01-31T23:00:00.000Z',
        agentNumber: '',
        wertnachweisWirdNachgereicht: true,
        vertragsunterlagenErhalten: true,
        erstinformationenGelesen: true,
        beratungprotokoll: true,
        wertnachweisFiles: []
      })
    }
  }
}
</script>
