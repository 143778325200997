<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Weitere Daten
      </h4>
    </template>
    <template #body>
      <form>
        <BasicSelect
          id="selectAction"
          v-model="order.carData.fahrzeugAuswahl"
          :required="required"
          label="Neuzulassung oder Versichererwechsel"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          horizontal
          placeholder="Bitte auswählen"
          :options="insuranceNewOrExistingOptions"
        >
          <template #slotinfotext>
            <p>
              <span class="font-weight-bold">Neuzulassung / Ummeldung</span>
              <ul class="ml-n4">
                <li>Das Fahrzeug ist derzeit nicht angemeldet und soll neu zugelassen werden.</li>
                <li>Das Fahrzeug ist auf eine andere Person (z.B. den Vorbesitzer) angemeldet und soll umgemeldet werden.</li>
              </ul>
              Von der Zulassungsbehörde wird uns im Nachgang der tatsächliche Zulassungstag und somit der korrekte Versicherungsbeginn mitgeteilt.
              <br><br>
              <span class="font-weight-bold">Wechsel der Versicherung</span><br>
              Das Fahrzeug ist derzeit angemeldet und anderweitig versichert. Die Versicherung soll gewechselt werden, eine Ab- oder Ummeldung des Fahrzeugs erfolgt nicht.
            </p>
          </template>
        </BasicSelect>

        <transitionGroup
          name="slide-fade"
        >
          <div
            v-if="order.carData.fahrzeugAuswahl === insuranceNewOrExisting.insuranceNewCar.api"
            key="fahrzeugAuswahlInsuranceNewCar"
            class="row my-3"
          >
            <div class="col-12 col-md-4">
              Gewünschter Versicherungsbeginn
            </div>
            <div class="col-12 col-md-8">
              Tag der Zulassung
            </div>
          </div>

          <template v-if="order.carData.fahrzeugAuswahl === insuranceNewOrExisting.insuranceExistingCar.api">
            <VersicherungsbeginnKennzeichenFIN
              key="1"
              :order="order"
              :required="required"
              :disable-versicherungsbeginn="isNachtrag"
            />
          </template>
        </transitionGroup>
        <div class="form-row">
          <div class="col-12 col-md-8">
            <BasicInput
              v-model="order.carData.kilometerstand"
              :validation="{numeric: true}"
              :required="required"
              name="Kilometerstand"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              horizontal
              label="Kilometerstand"
              placeholder="100000"
              :margin="false"
            >
              <template #append>
                <span class="input-group-text">km</span>
              </template>
            </BasicInput>
          </div>
          <div class="col-12 col-md-4">
            <BasicDatepicker
              v-model="order.carData.kilometerstand_datum"
              name="Kilometerstand am"
              :disabled-dates="disabledDatesKilometerstand"
              :columns="{label: ['col'], input:['col-12']}"
              horizontal
              :margin="false"
              placeholder="TT.MM.JJJJ"
              :required="required"
            />
          </div>
        </div>

        <BasicSelect
          v-model="order.wertnachweis"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          horizontal
          :required="required"
          name="Angaben zum Wertnachweis"
          label="Angaben zum Wertnachweis"
          placeholder="Bitte auswählen"
          :options="wertnachweisFiltered"
        />
        <div v-if="order.wertnachweis === 'digital_selfassessment' && client && client.selbstbewertungFile && client.selbstbewertungFile.downloadUrl">
          <div class="row mb-4">
            <div class="col-12 col-md-4" />
            <div class="col-12 col-md-8">
              <SignedDownloadLink :file="client.selbstbewertungFile">
                <template
                  #default="{link}"
                >
                  <div>
                    <a
                      target="_blank"
                      :href="link"
                    >
                      <i class="fas fa-file-pdf" /> Eigenbewertung</a>
                    <span>
                      <i
                        id="eigenbewertunginfo"
                        class="far fa-info-circle text-gray-4 ml-2"
                      />
                      <b-popover
                        target="eigenbewertunginfo"
                        placement="top"
                        triggers="hover focus"
                        content="Sie können dieses Formular hier herunterladen, ausfüllen, speichern und wieder hochladen."
                      />
                    </span>
                  </div>
                </template>
              </SignedDownloadLink>
            </div>
          </div>
        </div>
        <BasicDatepicker
          v-model="order.wertnachweisDatum"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          horizontal
          name="Erstelldatum des Wertnachweises"
          label="Erstelldatum des Wertnachweises"
          :required="!order.wertnachweisWirdNachgereicht && required"
          :disabled-dates="disabledDatesWertnachweis"
          infotext="Bitte tragen Sie hier das Erstellungsdatum der Fahrzeugbewertung ein."
        />
        <div class="form-row">
          <div class="col-12 col-md-4" />
          <div class="col-12 col-md-8">
            <Hardknockout
              v-if="isHardknockoutWertnachweisDatum"
              message="Der Wertnachweis darf nicht älter als 3 Jahre sein und nicht in der Zukunft liegen."
            />
            <FormNote
              message="Bei Vertragsbeginn darf der Wertnachweis nicht älter als 3 Jahre sein. Sie können den Wertnachweis aber auch jederzeit nachreichen."
            />
          </div>
        </div>
        <BasicFileUpload
          v-model="order.wertnachweisFiles"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          horizontal
          label="Upload Wertnachweis"
          :required="!order.wertnachweisWirdNachgereicht && required"
          multiple
          infotext="Hier können Sie den Wertnachweis hochladen. Der Wertnachweis darf nicht älter als drei Jahre sein."
        />
        <BasicCheckbox
          v-model="order.wertnachweisWirdNachgereicht"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          horizontal
          name="wird nachgereicht"
          label="wird nachgereicht"
          infotext="Der Wertnachweis muss innerhalb von sechs Wochen nach Vertragsbeginn eingereicht werden und darf nicht älter als drei Jahre sein."
        />
        <ChangeWkzInput
          v-if="isAngebotEdit"
          :order="order"
        />
        <hr>
        <BasicTypeahead
          id="vermittlernummer"
          v-model="order.agentNumber"
          name="Vermittler"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Vermittlernummer"
          :query="searchAgentNumber"
          :input-formatter="vermittlerInputFormatter"
          :result-formatter="agentResultFormatter"
          :horizontal="true"
          label="Vermittler"
          :margin="false"
          :disabled="!$can('editVermittlernummer')"
          strict
          @hit="setAgentNumber"
          @unselect="deleteAgent"
        />
        <p
          v-if="customerAgents && customerAgents.length && $can('editVermittlernummer') && !(customerAgents.length === 1 && customerAgents[0] === order.agentNumber)"
          class="text-danger"
        >
          Achtung! Der ausgewählte Kunde hat bereits Verträge bei folgenden Vermittlern: {{ customerAgents.join(', ') }}
        </p>
        <BasicInput
          v-if="order.agent && order.agent.agentNumberExtern"
          v-model="order.agent.agentNumberExtern"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Vermittlernummer Extern"
          label="Vermittlernummer Extern"
          horizontal
          disabled
        />
        <BasicInput
          v-if="$can('setUntervermittler') || me.canSetUntervermittler"
          v-model="order.untervermittlerNummer"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Untervermittlernummer"
          label="Untervermittlernummer"
          horizontal
        />
      </form>
    </template>
  </ca-card>
</template>

<script>
import CaCard from '@/components/Card.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker.vue'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox.vue'
import BasicFileUpload from '@/components/BaseComponents/BasicFileUpload.vue'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead.vue'

import enumsToSelectOptions from '@/helpers/enumToSelectOptions'
import wertnachweis from '@/resources/enums/wertnachweis.js'

import getSegmentByEZ from '@/helpers/getSegmentByEZ'
import moment from 'moment'
import FormNote from './FormNote.vue'
import Hardknockout from './Hardknockout.vue'
import roles from '@/resources/enums/roles'
import insuranceNewOrExisting from '@/resources/enums/insuranceNewOrExisting'
import VersicherungsbeginnKennzeichenFIN from '@/components/Forms/Order/VersicherungsbeginnKennzeichenFIN.vue'
import ChangeWkzInput from './components/ChangeWkzInput.vue'
import { defineComponent } from '@vue/composition-api'
import feathersClient from '@/api'
import SignedDownloadLink from '@/components/SignedDownloadLink.vue'

export default defineComponent({
  inject: ['$validator'],
  components: {
    CaCard,
    BasicInput,
    BasicSelect,
    BasicDatepicker,
    BasicCheckbox,
    BasicFileUpload,
    FormNote,
    Hardknockout,
    BasicTypeahead,
    VersicherungsbeginnKennzeichenFIN,
    ChangeWkzInput,
    SignedDownloadLink
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    isNachtrag: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    isAngebotEdit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showFahrerkreis: true,
    roles,
    insuranceNewOrExisting,
    customerAgents: null,
    client: null
  }),
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    insuranceNewOrExistingOptions () {
      return Object.values(insuranceNewOrExisting).map(type => ({ label: type.human, value: type.api }))
    },
    disabledDatesKilometerstand () {
      return {
        from: new Date(moment().startOf('day'))
      }
    },
    segment () {
      if (!this.order.carData) return null
      return getSegmentByEZ(this.order.carData.erstzulassung)
    },
    wertnachweisFiltered () {
      let versicherungswert = 0
      if (this.order.carData.versicherungswert) versicherungswert = this.order.carData.versicherungswert
      let wiederaufbauwert = 0
      if (this.order.wiederherstellungswert) wiederaufbauwert = this.order.wiederherstellungswert
      const sum = versicherungswert + wiederaufbauwert
      if (this.segment === 'PremiumCars') { // Kaufbeleg und detailierte Wertgutachten erlaubt
        return enumsToSelectOptions(Object.assign([wertnachweis.purchaseDocument_invoice, wertnachweis.detailed_appraisal]))
      } else {
        if (sum <= 130000) { // Kurzbewertung, Detailiertes und Selbstbewertung
          return enumsToSelectOptions(Object.assign([wertnachweis.brief_valuation, wertnachweis.detailed_appraisal, wertnachweis.digital_selfassessment]))
        } else { // nur detailiertes Gutachten
          return enumsToSelectOptions(Object.assign([wertnachweis.detailed_appraisal]))
        }
      }
    },
    disabledDatesWertnachweis () {
      if (this.isNachtrag) {
        return {
          to: null,
          from: null
        }
      }
      return {
        to: new Date(moment().startOf('day').subtract(3, 'years')),
        from: new Date(moment().startOf('day'))
      }
    },
    isHardknockoutWertnachweisDatum () {
      if (!this.order.wertnachweisDatum || this.isNachtrag) return false
      return moment(this.order.wertnachweisDatum).startOf('day').isBefore(moment(this.disabledDatesWertnachweis.to)) ||
      moment(this.order.wertnachweisDatum).startOf('day').isAfter(moment(this.disabledDatesWertnachweis.from))
    },
    isSomeHardknockouts () {
      return this.isHardknockoutWertnachweisDatum
    }
  },
  watch: {
    customer: {
      immediate: true,
      handler: async function (newVal, oldVal) {
        if (!this.customer || !this.customer._id) return
        const allOrdersFromCustomer = await feathersClient.service('hs-order').find({
          query: {
            $client: { paginate: false },
            customerId: this.customer._id,
            $select: ['agentNumber']
          }
        })
        const agentNumbers = allOrdersFromCustomer.map(el => el.agentNumber).filter(el => el)
        this.customerAgents = [...new Set(agentNumbers)]
      }
    },
    wertnachweisFiltered: function (newVal, oldVal) {
      if (!this.wertnachweisFiltered.find(wertnachweis => wertnachweis.value === this.order.wertnachweis)) this.$delete(this.order, 'wertnachweis')
    },
    'order.wertnachweis': function (newVal, oldVal) {
      if (this.order.wertnachweis === 'digital_selfassessment') this.order.wertnachweisDatum = new Date()
    },
    'order.wertnachweisFiles': function (newVal, oldVal) {
      this.$set(this.order, 'wertnachweisFileIds', this.order.wertnachweisFiles.map(file => file._id))
    },
    isSomeHardknockouts: {
      handler: function (newVal) {
        this.$emit('hardknockout-change', newVal)
      }
    },
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  created () {
    if (this.me.agentNumber && !this.$can('editVermittlernummer') && !this.order.agentNumber) {
      this.setAgentNumber(this.me)
    }
  },
  methods: {
    async searchAgentNumber (number) {
      return await this.$store.dispatch('users/find', {
        query: {
          $or: [
            { agentNumber: { $regex: '^' + number, $options: 'i' } },
            { traderId: { $regex: '^' + number, $options: 'i' } },
            { firstname: { $regex: '^' + number, $options: 'i' } },
            { lastname: { $regex: '^' + number, $options: 'i' } }
          ],
          $limit: 20
        }
      })
    },
    agentResultFormatter (user) {
      return `${user.agentNumber}: ${user.firstname} ${user.lastname}`
    },
    setAgentNumber (user) {
      this.$set(this.order, 'userId', user._id)
      this.$set(this.order, 'agentNumber', user.agentNumber)
      this.$set(this.order, 'agent', { agentNumberExtern: user.agentNumberExtern })
    },
    deleteAgent () {
      this.$set(this.order, 'userId', this.me._id)
      this.$set(this.order, 'agentNumber', '')
    },
    vermittlerInputFormatter (user) {
      if (typeof user === 'object') {
        return user.agentNumber
      }
      return user
    },
    async fetchData () {
      try {
        this.loading = true
        const clientReq = await this.$store.dispatch('clients/find', {
          query: {
            $limit: 1
          }
        })
        this.client = clientReq.data[0]
      } catch (error) {
        this.loadingError = error
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
})
</script>
