<template>
  <div>
    <BasicDatepicker
      key="1"
      v-model="order.versicherungsbeginn"
      :disabled="disableVersicherungsbeginn"
      :required="required"
      label="Gewünschter Versicherungsbeginn"
      :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
      horizontal
      :margin="false"
      placeholder="TT.MM.JJJJ"
      :disabled-dates="disabledDatesVersicherungsbeginn"
      infotext="Bitte geben Sie hier den gewünschten Versicherungsbeginn ein. Dieses Datum ist gleichzeitig auch das Datum, zu welchem Sie den Vertrag bei der bisherigen Versicherung gekündigt haben sollten oder kündigen werden."
    />
    <BasicCheckbox
      v-if="(isVersicherungsbeginnPast && order.carData.fahrzeugAuswahl === insuranceNewOrExisting.insuranceExistingCar.api) && !disableVersicherungsbeginn"
      key="2"
      v-model="keineSchaeden"
      required
      :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
      horizontal
      label="An dem Fahrzeug sind seit dem gewünschten Versicherungsbeginn bis heute keine Schäden eingetreten"
      infotext="Sie haben als gewünschten Versicherungsbeginn ein Datum in der Vergangenheit angegeben. Hiermit bestätigen Sie, dass seit dem gewünschten Versicherungsbeginn bis heute keine Schäden eingetreten sind."
    />
    <LicensePlateInput
      v-show="order.carData.kennzeichenart !== 10"
      key="3"
      :order="order"
      class="my-3"
      :required="required && order.carData.kennzeichenart !== 10"
      infotext="Bitte tragen Sie hier das aktuelle Kennzeichen ein. Dieses können Sie der Zulassungsbescheinigung entnehmen."
    />
    <BasicInput
      key="4"
      v-model="order.carData.fahrzeugID"
      :required="required"
      :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
      horizontal
      label="Fahrzeugidentifikationsnummer"
      placeholder="Fahrzeugidentifikationsnummer"
      :validation="{alpha_num: true}"
      infotext="Bitte tragen Sie hier die vollständige Fahrgestellnummer des Fahrzeugs ein. Diese können Sie der Zulassungsbescheinigung entnehmen."
    />
  </div>
</template>

<script>
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker.vue'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import LicensePlateInput from '@/components/LicensePlateInput.vue'
import dayjs from 'dayjs'
import insuranceNewOrExisting from '@/resources/enums/insuranceNewOrExisting'

export default {
  components: {
    BasicDatepicker,
    BasicCheckbox,
    BasicInput,
    LicensePlateInput
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    required: {
      type: Boolean,
      default: true
    },
    disableVersicherungsbeginn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      insuranceNewOrExisting,
      keineSchaeden: false
    }
  },
  computed: {
    isVersicherungsbeginnPast () {
      return dayjs(this.order.versicherungsbeginn).isBefore(dayjs().startOf('day'))
    },
    disabledDatesVersicherungsbeginn () {
      const currentYear = dayjs().add(2, 'months').year() + 1
      return {
        to: new Date(dayjs().startOf('day').subtract(3, 'months')), // Versicherungsbeginn darf nicht weiter als 3 Monate in der Vergangenheit liegen
        from: new Date(dayjs(`${currentYear}-05-02`).startOf('day')) // Versicherungsbeginn darf max 01.05. des Folgejahres sein
      }
    }
  }
}
</script>
