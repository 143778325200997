<template>
  <Card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Zahlungsinformationen
      </h4>
    </template>
    <template #body>
      <form>
        <basic-select
          v-model="order.zahlart"
          :columns="{
            label: ['col-12', 'col-md-4'],
            input: ['col-12', 'col-md-8'],
          }"
          :horizontal="true"
          label="Zahlart"
          :required="required"
          :options="filteredZahlarten"
        />
        <template v-if="order.zahlart === 'SEPA'">
          <BasicCheckbox
            v-model="order.isAbwKontoinhaber"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Abweichender Kontoinhaber"
          />
          <transition name="slide-fade">
            <div v-if="order.isAbwKontoinhaber">
              <div class="form-row">
                <div class="col-12 col-md-8">
                  <BasicInput
                    v-model="order.abwKontoinhaber.VOR"
                    label="Abw. Kontoinhaber"
                    :columns="{
                      label: ['col-12', 'col-md-6'],
                      input: ['col-12', 'col-md-6'],
                    }"
                    :horizontal="true"
                    :required="required"
                    placeholder="Vorname"
                  />
                </div>
                <div class="col-12 col-md-4">
                  <BasicInput
                    v-model="order.abwKontoinhaber.NAM"
                    name="Nachname"
                    placeholder="Nachname"
                    :required="required"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 col-md-8">
                  <BasicInput
                    v-model="order.abwKontoinhaber.STR"
                    label="Adresse abw. Kontoinhaber"
                    :columns="{
                      label: ['col-12', 'col-md-6'],
                      input: ['col-12', 'col-md-6'],
                    }"
                    :horizontal="true"
                    name="Straße"
                    :required="required"
                    placeholder="Straße"
                    :margin="false"
                  />
                </div>
                <div class="col-12 col-md-4">
                  <BasicInput
                    v-model="order.abwKontoinhaber.HNR"
                    name="Hausnummer"
                    placeholder="Nr."
                    :required="required"
                    :margin="false"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 col-md-8">
                  <BasicInput
                    v-model="order.abwKontoinhaber.PLZ"
                    name="Postleitzahl"
                    :columns="{
                      label: ['col-12', 'col-md-6'],
                      input: ['col-12', 'col-md-6'],
                    }"
                    :horizontal="true"
                    :required="required"
                    placeholder="PLZ"
                  />
                </div>
                <div class="col-12 col-md-4">
                  <BasicInput
                    v-model="order.abwKontoinhaber.ORT"
                    name="Ort"
                    placeholder="Ort"
                    :required="required"
                  />
                </div>
              </div>
            </div>
          </transition>
          <BasicIbanInput
            v-model="order"
            required
          />
        </template>
      </form>
      <template />
    </template>
  </Card>
</template>

<script>
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import Card from '@/components/Card.vue'
import zahlarten from '@/resources/enums/zahlarten'
import BasicIbanInput from '@/components/BaseComponents/BasicIbanInput.vue'

export default {
  components: {
    BasicCheckbox,
    BasicInput,
    Card,
    BasicSelect,
    BasicIbanInput
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    zahlarten () {
      return Object.values(zahlarten).map(zahlart => ({ label: zahlart.human, value: zahlart.api }))
    },
    isFilteredZahlarten () {
      if (!this.order.zahlweise) return false
      return this.order.zahlweise !== 112
    },
    filteredZahlarten () {
      if (!this.isFilteredZahlarten) return this.zahlarten
      return this.zahlarten.filter(zahlart => zahlart.value !== 'Rechnung')
    }
  },
  watch: {
    isFilteredZahlarten: function () {
      if (!this.filteredZahlarten.find(zahlart => zahlart.value === this.order.zahlart)) this.$delete(this.order, 'zahlart')
    }
  }
}
</script>
